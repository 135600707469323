import axios from "axios"
import { apiURL } from "../../config.js"

const api = axios.create({
  baseURL: apiURL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
  xhrFields: {
    withCredentials: true,
  },
})

export default api
