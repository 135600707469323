import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import i18n from "@/i18n"
import "@/sass/overrides.sass"

Vue.use(Vuetify)

const theme = {
  background: "#E5E5E5",
  primary: "#F49700",
  secondary: "#CFDBD5",
  accent: "#e1b753",
  info: "#0094e3",
}

export default new Vuetify({
  xs: true,
  sm: true,
  md: true,
  lg: true,
  xl: true,
  breakpoint: {
    thresholds: {
      xs: 340,
      sm: 540,
      md: 1280,
    },
    scrollbarWidth: 0,
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
