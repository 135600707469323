import api from "@/api/api"
import common from "@/utils/common"
const snackbarType = common.data.snackbarType

const state = () => ({
  equipment: null,
  csrDetail: null,
  customerId: null,
  agentDetail: {},
  modes: [
    {
      id: null,
      name: null,
    },
  ],
  orderTypes: [
    {
      id: null,
      name: null,
    },
  ],
  loadingIcon: false,
  agentId: 0,
  equipmentTypes: [
    {
      id: null,
      name: null,
    },
  ],
  parentId: 0,
  loadId: null,
  countries: [
    {
      id: null,
      name: null,
    },
  ],
  locationId: null,
  selectedLocation: null,
})

const getters = {
  equipmentTypes: (state, getters) => {
    return state.equipmentTypes
  },
  equipment: (state, getters) => {
    return state.equipment
  },
  csrDetail: (state) => {
    return state.csrDetail
  },
  customerId: (state) => {
    return state.customerId
  },
  agentDetail: (state) => {
    return state.agentDetail
  },
  modes: (state) => {
    return state.modes
  },
  orderTypes: (state) => {
    return state.orderTypes
  },
  loadingIcon: (state) => {
    return state.loadingIcon
  },
  agentId: (state) => {
    return state.agentId
  },
  parentId: (state) => {
    return state.parentId
  },
  loadId: (state) => {
    return state.loadId
  },
  countries: (state) => {
    return state.countries
  },
  locationId: (state) => {
    return state.locationId
  },
  selectedLocation: (state) => {
    return state.selectedLocation
  },
  accountManager: (state) => {
    return state.accountManager
  },
}

const actions = {
  GET_EQUIPMENT_TYPE: ({ commit }) => {
    const equipmentItems = common.methods.getWithExpiry("equipment_types")
    if (equipmentItems) {
      commit("setEquipmentType", equipmentItems)
    } else {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/loads/api/equipment-type-choices/`
        )
        .then((response) => {
          const _data = response.data
          if (_data.length > 0) {
            const equipmentData = []
            for (let i = 0; i < _data.length; i++) {
              const data = _data[i]
              const _equipment = {
                id: data.id,
                name: data.name || "None",
              }
              equipmentData.push(_equipment)
            }
            common.methods.setWithExpiry(
              "equipment_types",
              equipmentData,
              process.env.VUE_APP_LOCALSTORAGE_TIME
            )
            common.data.equipmentType = equipmentData
            commit("setEquipmentType", equipmentData)
          }
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve equipment.",
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
        })
    }
  },
  GET_AGENT_DETAIL: ({ commit }) => {
    return new Promise((resolve, reject) => {
      api
        .get(`${process.env.VUE_APP_BASE_URL}/accounts/api/agent/detail/`)
        .then((response) => {
          const detail = response.data
          const agentDetail = {
            user: {
              id: detail.user.id,
              lastLogin: detail.user.last_login,
              isSuperuser: detail.user.is_superuser,
              username: detail.user.username,
              firstName: detail.user.first_name,
              lastName: detail.user.last_name,
              email: detail.user.email,
              isStaff: detail.user.is_staff,
              isActive: detail.user.is_active,
              dateJoined: detail.user.date_joined,
              isCustomer: detail.user.is_customer,
              isAgent: detail.user.is_agent,
              isCarrier: detail.user.is_carrier,
              isShipper: detail.user.is_shipper,
            },
            agentCompanyId: detail.agent_company_id,
            officeId: detail.office_id,
            phone: detail.phone,
            admin: detail.admin,
            accounting: detail.accounting,
            carrierRelations: detail.carrier_relations,
            mapType: detail.map_type,
          }
          commit("setAgentDetail", agentDetail)
          resolve(agentDetail)
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve agent detail.",
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  GET_MODES_CHOICES: ({ commit }) => {
    const modeTypes = common.methods.getWithExpiry("mode_types")
    if (modeTypes) {
      commit("setModes", modeTypes)
    } else {
      api
        .get(`${process.env.VUE_APP_BASE_URL}/loads/api/load-mode-types/`)
        .then((response) => {
          const _data = response.data
          if (_data.length > 0) {
            const modeData = []
            for (let i = 0; i < _data.length; i++) {
              const data = _data[i]
              const mode = {
                id: data.id,
                name: data.name || "None",
              }
              modeData.push(mode)
            }
            common.methods.setWithExpiry(
              "mode_types",
              modeData,
              process.env.VUE_APP_LOCALSTORAGE_TIME
            )
            commit("setModes", modeData)
          }
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve mode type.",
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
        })
    }
  },
  GET_ORDER_TYPES: ({ commit }) => {
    const orderTypes = common.methods.getWithExpiry("order_types")
    if (orderTypes) {
      commit("setOrderTypes", orderTypes)
    } else {
      api
        .get(`${process.env.VUE_APP_BASE_URL}/loads/api/order-type-choices/`)
        .then((response) => {
          const _data = response.data
          if (_data.length > 0) {
            const orderTypeData = []
            for (let i = 0; i < _data.length; i++) {
              const data = _data[i]
              const orderType = {
                id: data.id,
                name: data.name || "None",
              }
              orderTypeData.push(orderType)
            }
            common.methods.setWithExpiry(
              "order_types",
              orderTypeData,
              process.env.VUE_APP_LOCALSTORAGE_TIME
            )
            commit("setOrderTypes", orderTypeData)
          }
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve mode type.",
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
        })
    }
  },
  GET_COUNTRIES: ({ commit }) => {
    const countriesList = common.methods.getWithExpiry("countries_list")
    if (countriesList) {
      commit("setCountries", countriesList)
    } else {
      api
        .get(`${process.env.VUE_APP_BASE_URL}/locations/api/country-list/`)
        .then((response) => {
          const _data = response.data
          if (_data.length > 0) {
            const countriesData = []
            for (let i = 0; i < _data.length; i++) {
              const data = _data[i]
              const country = {
                id: data.id,
                name: data.name || "None",
              }
              countriesData.push(country)
            }
            common.methods.setWithExpiry(
              "countries_list",
              countriesData,
              process.env.VUE_APP_LOCALSTORAGE_TIME
            )
            commit("setCountries", countriesData)
          }
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve countries.",
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
        })
    }
  },
}

const mutations = {
  setEquipmentType(state, payload) {
    state.equipmentTypes = payload
  },
  setEquipment(state, payload) {
    state.equipment = payload
  },
  setCSRDetail(state, payload) {
    state.csrDetail = payload
  },
  setCustomerId(state, payload) {
    state.customerId = payload
  },
  setAgentDetail(state, payload) {
    state.agentDetail = payload
  },
  setModes(state, payload) {
    state.modes = payload
  },
  setOrderTypes(state, payload) {
    state.orderTypes = payload
  },
  setLoadingIcon(state, payload) {
    state.loadingIcon = payload
  },
  setAgentId(state, payload) {
    state.agentId = payload
  },
  setParentId(state, payload) {
    state.parentId = payload
  },
  setLoadId(state, payload) {
    state.loadId = payload
  },
  setCountries(state, payload) {
    state.countries = payload
  },
  setLocationId(state, payload) {
    state.locationId = payload
  },
  setSelectedLocation(state, payload) {
    state.selectedLocation = payload
  },
  setAccountManager(state, payload) {
    state.accountManager = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
