<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Exo Freight"
      },
    },
  },
  mounted() {
    // Firebase Messaging
    this.$store.dispatch("main/MOUNT_FIREBASE_CONFIG")
    if (this.$cookie.get("token")) {
      this.$store.dispatch("generic/GET_AGENT_DETAIL")
      this.loadInitialData()
    }
  },
  methods: {
    loadInitialData() {
      this.$store.dispatch("generic/GET_EQUIPMENT_TYPE")
      this.$store.dispatch("generic/GET_MODES_CHOICES")
      this.$store.dispatch("generic/GET_ORDER_TYPES")
      this.$store.dispatch("carrier/GET_CARRIER_INSURANCE_TYPES")
      this.$store.dispatch("loadDetail/GET_LOAD_STATUS_CHOICES")
      this.$store.dispatch("loadDetail/GET_LOADBOARDS")
      this.$store.dispatch("generic/GET_COUNTRIES")
    },
  },
}
</script>
