import api from "@/api/api"
import common from "@/utils/common"
import _camelCase from "lodash/camelCase"
import _snakeCase from "lodash/snakeCase"
import _mapKeys from "lodash/mapKeys"
import _isEqual from "lodash/isEqual"

const snackbarType = common.data.snackbarType

const getInitialACHForm = () => ({
  name: "",
  isPrimary: false,
  paymentMethod: "ACH",
  accountType: "CHECKING",
  accountNumber: "",
  routingNumber: "",
})

const getInitialAddressForm = () => ({
  name: "",
  isPrimary: false,
  businessName: "",
  address: "",
  apt: "",
  city: "",
  country: "USA",
  state: "",
  postalCode: "",
})

const getInitialCheckForm = () => ({
  ...getInitialAddressForm(),
  paymentMethod: "CHECK",
})

const getInitialFactoringForm = () => ({
  ...getInitialAddressForm(),
  paymentMethod: "FACTORING",
  phone: '',
  email: '',
  contactName: '',
})

const state = {
  paymentMethods: [],
  ACHForm: getInitialACHForm(),
  checkForm: getInitialCheckForm(),
  factoringForm: getInitialFactoringForm(),
  ACHFormIsValid: false,
  checkFormIsValid: false,
  factoringFormIsValid: false,
  selectedPaymentMethodChoice: "ACH",
  isAddingPaymentMethod: false,
  actionLoadingForPaymentMethodId: {}
}

const getters = {
  paymentMethods: state => state.paymentMethods,
  paymentMethod: state => ({"ACH":state.ACHForm, "CHECK":state.checkForm, "FACTORING": state.factoringForm })[state.selectedPaymentMethodChoice],
  selectedPaymentMethodChoice: state => state.selectedPaymentMethodChoice,
  isAddingPaymentMethod: state => state.isAddingPaymentMethod,
  formIsValid: state => ({"ACH":state.ACHFormIsValid, "CHECK":state.checkFormIsValid, "FACTORING": state.factoringFormIsValid })[state.selectedPaymentMethodChoice],
  paymentMethodIsDeleting: state => paymentMethodId =>
    Boolean(state.actionLoadingForPaymentMethodId[paymentMethodId]?.deleting)
  ,
  paymentMethodIsBeingSetAsPrimary: state => paymentMethodId =>
    Boolean(state.actionLoadingForPaymentMethodId[paymentMethodId]?.settingAsPrimary)
}

const actions = {
  GET_PAYMENT_METHODS: ({ commit }, carrierId) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/billing/api/carrier-company-payment-methods/`, {
          params: { carrier_id: carrierId },
        }
      )
      .then((response) => {
        const camelCasePaymentMethods = response.data.map(item => _mapKeys(item, (_, key) => _camelCase(key)))
        commit("setPaymentMethods", camelCasePaymentMethods)
      })
      .catch(() => {
        const snackbar = {
            status: true,
            text: "Failed to retrieve payment methods",
            type: snackbarType.error,
        }

        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
      .finally(() => commit("generic/setLoadingIcon", false, { root: true }))
  },
  ADD_PAYMENT_METHOD: async ({ commit, getters, dispatch }, carrierId) => {
    if (!getters.formIsValid) return

    commit("setIsAddingPaymentMethod", true)

    let newPaymentMethod = {
      ...getters.paymentMethod,
      carrierId,
    }

    newPaymentMethod = _mapKeys(newPaymentMethod, (_, key) => _snakeCase(key))

    return api
      .post(
        `${process.env.VUE_APP_BASE_URL}/billing/api/carrier-company-payment-methods/`, newPaymentMethod
      )
      .then(() => {
        const snackbar = {
          status: true,
          text: "Payment method added successfully",
          type: snackbarType.success,
        }
        
        dispatch("GET_PAYMENT_METHODS", carrierId)
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        commit("resetForms")
      })
      .catch((e) => {
        const isRoutingError = JSON.stringify(e.response.data).includes("routing_number")
        const snackbar = {
          status: true,
          text: isRoutingError ? "Routing number could not be found" : e.response.data,
          type: snackbarType.error,
        }

        commit("main/SET_SNACKBAR", snackbar, { root: true })

        return Promise.reject()
      })
      .finally(() => commit("setIsAddingPaymentMethod", false))
  },
  DELETE_PAYMENT_METHOD: async ({ commit, state }, { paymentMethodId, carrierId }) => {
    commit("setActionLoadingForPaymentMethodId", { paymentMethodId, isLoading: true, action: "deleting" })

    return api
      .delete(`/billing/api/carrier-company-payment-method-ud/${paymentMethodId}/`, {
        data: {
          carrier_id: carrierId,
        }
      }).then(() => {
        const snackbar = {
          status: true,
          text: "Payment method deleted successfully",
          type: snackbarType.success,
        }

        commit("main/SET_SNACKBAR", snackbar, { root: true })
        commit("setPaymentMethods", state.paymentMethods.filter(item => item.id !== paymentMethodId))
      }).catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to delete payment method, try again later",
          type: snackbarType.error,
        }

        commit("main/SET_SNACKBAR", snackbar, { root: true })
      }).finally(() =>
        commit("setActionLoadingForPaymentMethodId", { paymentMethodId, isLoading: false, action: "deleting" })
      )
  },
  SET_PRIMARY_PAYMENT_METHOD: async ({ commit, state }, { paymentMethodId, carrierId }) => {
    commit("setActionLoadingForPaymentMethodId", { paymentMethodId, isLoading: true, action: "settingAsPrimary" })

    return api
      .patch(`/billing/api/carrier-company-payment-method-ud/${paymentMethodId}/`, {
        is_primary: true,
        carrier_id: carrierId,
      }).then(() => {
        const snackbar = {
          status: true,
          text: "Primary payment method updated successfully",
          type: snackbarType.success,
        }

        commit("main/SET_SNACKBAR", snackbar, { root: true })
        commit("setPaymentMethods", state.paymentMethods.map(item => ({
          ...item,
          isPrimary: item.id === paymentMethodId ? true : false,
        })))
      }).catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to set primary payment method, try again later",
          type: snackbarType.error,
        }

        commit("main/SET_SNACKBAR", snackbar, { root: true })
      }).finally(() => {
        commit("setActionLoadingForPaymentMethodId", { paymentMethodId, isLoading: false, action: "settingAsPrimary" })
      })
  }
}

const mutations = {
    setPaymentMethods: (state, paymentMethods) => {
        state.paymentMethods = paymentMethods
    },
    setSelectedPaymentMethodChoice: (state, paymentMethodChoice) => {
      state.selectedPaymentMethodChoice = paymentMethodChoice
    },
    setACHFormField: (state, { field, value}) => {
      state.ACHForm = {
        ...state.ACHForm,
        [field]: value,
      }
    },
    setACHFormIsValid: (state, isValid) => {
      state.ACHFormIsValid = isValid
    },
    setCheckFormField: (state, { field, value}) => {
      state.checkForm = {
        ...state.checkForm,
        [field]: value,
      }
    },
    setCheckFormIsValid: (state, isValid) => {
      state.checkFormIsValid = isValid
    },
    setFactoringFormField: (state, { field, value}) => {
      state.factoringForm = {
        ...state.factoringForm,
        [field]: value,
      }
    },
    setFactoringFormIsValid: (state, isValid) => {
      state.factoringFormIsValid = isValid
    },
    setIsAddingPaymentMethod: (state, value) => {
      state.isAddingPaymentMethod = value
    },
    resetForms: (state) => {
      state.ACHForm = getInitialACHForm()
      state.checkForm = getInitialCheckForm()
      state.factoringForm = getInitialFactoringForm()
    },
    setActionLoadingForPaymentMethodId: (state, { action, paymentMethodId, isLoading }) => {
      state.actionLoadingForPaymentMethodId = {
        ...state.actionLoadingForPaymentMethodId,
        [paymentMethodId]: {
          ...state.actionLoadingForPaymentMethodId[paymentMethodId] || {},
          [action]: isLoading
        }
      }
    },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
