import api from "@/api/api"
import firebase from "firebase/app"
import "firebase/messaging"

const state = () => ({
  drawer: null,
  snackbar: {
    status: false,
    text: null,
    error: null,
    response: null,
    type: "info",
  },
})

const getters = {
  snackbarData: (state, getters) => {
    return state.snackbar
  },
}

const actions = {
  SAVE_NOTIFICATION_TOKEN: ({ commit }, token) => {
    const payload = {
      registration_id: token,
      type: "web",
    }
    api
      .post("/register-notif-token/", payload)
      .then(() => {})
      .catch(() => {})
  },
  MOUNT_FIREBASE_CONFIG: ({ dispatch }) => {
    const config = {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
      appId: process.env.VUE_APP_FIREBASE_APP_ID,
    }
    firebase.initializeApp(config)

    const messaging = firebase.messaging()

    messaging.usePublicVapidKey(process.env.VUE_APP_FIREBASE_VAPID_KEY)

    messaging
      .requestPermission()
      .then(() => {
        messaging.getToken().then((token) => {
          dispatch("SAVE_NOTIFICATION_TOKEN", token)
        })
      })
      .catch(() => {})

    messaging.onTokenRefresh(function () {
      messaging
        .getToken()
        .then(function (newToken) {
          dispatch("SAVE_NOTIFICATION_TOKEN", newToken)
        })
        .catch(() => {})
    })
  },
}

const mutations = {
  SET_DRAWER(state, payload) {
    state.drawer = payload
  },
  SET_SNACKBAR(state, payload) {
    state.snackbar.status = payload.status
    state.snackbar.text = payload.text
    state.snackbar.type = payload.type
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
