const state = () => ({
  carrierSalesRepDashboardIframeUrl: null,
  goalsDashboardUrl: null,
})

const getters = {
  carrierSalesRepDashboardIframeUrl: (state) => {
    return state.carrierSalesRepDashboardIframeUrl
  },
  goalsDashboardUrl: (state) => {
    return state.goalsDashboardUrl
  },
}

const actions = {
  MOUNT_CARRIER_SALES_REP_DASHBOARD: ({ commit }, agentDetail) => {
    const jwt = require("jsonwebtoken")

    const METABASE_SITE_URL = process.env.VUE_APP_METABASE_SITE_URL
    const METABASE_SECRET_KEY = process.env.VUE_APP_METABASE_SECRET_KEY
    const payload = {
      resource: { dashboard: 11 },
      params: {
        id: agentDetail.user.id,
      },
      exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
    }
    const token = jwt.sign(payload, METABASE_SECRET_KEY)

    const iframeUrl =
      METABASE_SITE_URL +
      "/embed/dashboard/" +
      token +
      "#bordered=true&titled=true"
    commit("SET_CARRIER_SALES_REP_DASHBOARD_URL", iframeUrl)
  },
  MOUNT_PBI_DASHBOARD: ({ commit }) => {
    const iframeUrl =
      "https://app.powerbi.com/reportEmbed?reportId=0cd8fbb5-cf83-402f-ba6f-effd9d21c5e5&autoAuth=true&ctid=95861c7e-ae99-4f0c-a211-12b4c55fbfca&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtZy1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9"
    commit("SET_GOALS_DASHBOARD_URL", iframeUrl)
  },
  MOUNT_GOALS_DASHBOARD: ({ commit, rootState }) => {
    const jwt = require("jsonwebtoken")

    const METABASE_SITE_URL = process.env.VUE_APP_METABASE_SITE_URL
    const METABASE_SECRET_KEY = process.env.VUE_APP_METABASE_SECRET_KEY

    const payload = {
      resource: { dashboard: 3 },
      params: {
        agent_company_id: rootState.generic.agentDetail.agentCompanyId,
      },
      exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
    }
    const token = jwt.sign(payload, METABASE_SECRET_KEY)

    const iframeUrl =
      METABASE_SITE_URL +
      "/embed/dashboard/" +
      token +
      "#bordered=true&titled=true"
    commit("SET_GOALS_DASHBOARD_URL", iframeUrl)
  },
}

const mutations = {
  SET_CARRIER_SALES_REP_DASHBOARD_URL(state, payload) {
    state.carrierSalesRepDashboardIframeUrl = payload
  },
  SET_GOALS_DASHBOARD_URL(state, payload) {
    state.goalsDashboardUrl = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
