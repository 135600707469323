import api from "@/api/api"
import common from "@/utils/common"
const snackbarType = common.data.snackbarType

const state = () => ({
  csvImportDialog: false,
  uploadProgress: 0,
  customerImportJobTypes: [],
  csvImportJobs: [],
  totalCsvImportJobs: 0,
})

const getters = {
  csvImportDialog: (state, getters) => {
    return state.csvImportDialog
  },
  uploadProgress: (state) => {
    return state.uploadProgress
  },
  customerImportJobTypes: (state) => {
    return state.customerImportJobTypes
  },
  csvImportJobs: (state) => {
    return state.csvImportJobs
  },
  totalCsvImportJobs: (state) => {
    return state.totalCsvImportJobs
  },
}

const actions = {
  GET_CSV_IMPORT_JOB_TYPES: ({ commit }) => {
    return new Promise((resolve, reject) => {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/customer-import-jobs/api/csv-job-type-choices/`
        )
        .then((response) => {
          const data = response.data
          commit("setCustomerImportJobTypes", data)
        })
    })
  },
  GET_CSV_IMPORT_JOBS: ({ commit }) => {
    return new Promise((resolve, reject) => {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/customer-import-jobs/api/load-csv-import/`
        )
        .then((response) => {
          const data = response.data.results
          commit("setCsvImportJobs", data)
        })
    })
  },
  POST_CSV_IMPORT: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/customer-import-jobs/api/load-csv-import/`,
          payload,
          {
            onUploadProgress: function (progressEvent) {
              commit(
                "setUploadProgress",
                parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                )
              )
            },
          }
        )
        .then((response) => {
          const snackbar = {
            status: true,
            text: "CSV import job created.",
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          dispatch("GET_CSV_IMPORT_JOBS", payload.carrierId)
          commit("setUploadProgress", 0)
          resolve(response)
        })
        .catch((error) => {
          const snackbar = {
            status: true,
            text: "Failed to create CSV import job.",
            type: snackbarType.error,
          }
          commit("setUploadProgress", 0)
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(error)
        })
    })
  },
}

const mutations = {
  setCsvImportDialog(state, payload) {
    state.csvImportDialog = payload
  },
  setUploadProgress(state, payload) {
    state.uploadProgress = payload
  },
  setCustomerImportJobTypes(state, payload) {
    state.customerImportJobTypes = payload
  },
  setCsvImportJobs: (state, payload) => {
    state.csvImportJobs = payload
  },
  setTotalCsvImportJobs: (state, payload) => {
    state.totalCsvImportJobs = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
