import api from "@/api/api"
import common from "@/utils/common"
const snackbarType = common.data.snackbarType
const state = () => ({
  factoringList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  currentFactoring: {},
  carrierCompanies: {
    count: null,
    next: null,
    previous: null,
    results: [],
  },
  factoringId: null,
  factoringPayments: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
})
const getters = {
  factoringList: (state) => {
    return state.factoringList
  },
  currentFactoring: (state) => {
    return state.currentFactoring
  },
  carrierCompanies: (state) => {
    return state.carrierCompanies
  },
  factoringId: (state) => {
    return state.factoringId
  },
  factoringPayments: (state) => {
    return state.factoringPayments
  },
}
const actions = {
  GET_FACTORING_COMPANIES: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/accounts/api/factoring/list-create/`,
          {
            params: payload,
          }
        )
        .then((response) => {
          const factoringData = {
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            results: [],
          }
          const data = response.data.results
          for (let i = 0; i < data.length; i++) {
            const record = data[i]
            const factoring = {
              id: record.id,
              businessName: record.business_name,
              addressLine1: record.address_line_1,
              city: record.city,
              state: record.state,
              postalCode: record.postal_code,
              country: record.country,
              phone: record.phone,
              email: record.email,
              contactName: record.contact_name,
            }
            factoringData.results.push(factoring)
          }
          commit("setFactoringList", factoringData)
          commit("generic/setLoadingIcon", false, { root: true })
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve factoring companies.",
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e.response)
        })
    })
  },
  GET_FACTORING_COMPANY: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/accounts/api/factoring/detail/${payload}/`
        )
        .then((response) => {
          const data = response.data
          const factoringInfo = {
            id: data.id,
            businessName: data.business_name,
            addressLine1: data.address_line_1,
            city: data.city,
            state: data.state,
            postalCode: data.postal_code,
            country: data.country,
            phone: data.phone,
            email: data.email,
            contactName: data.contact_name,
          }
          commit("setCurrentFactoring", factoringInfo)
          commit("generic/setLoadingIcon", false, { root: true })
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve factoring companies.",
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e.response)
        })
    })
  },
  PUT_FACTORING_COMPANY: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .put(
          `${process.env.VUE_APP_BASE_URL}/accounts/api/factoring/detail/${payload.factoringId}/`,
          payload.data
        )
        .then((response) => {
          const snackbar = {
            status: true,
            text: `${payload.data.name} has been updated.`,
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("generic/setLoadingIcon", false, { root: true })
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to update factoring company.",
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e.response)
        })
    })
  },
  GET_FACTORING_COMPANY_CARRIER_LIST: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/accounts/api/factoring/carrier-list/${payload.id}/`,
          {
            params: payload.params,
          }
        )
        .then((response) => {
          const carrierData = {
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            results: [],
          }
          const data = response.data.results
          for (let i = 0; i < data.length; i++) {
            const carrierDataRecord = data[i]
            const carrier = {
              id: carrierDataRecord.id,
              name: carrierDataRecord.name,
              mcNumber: carrierDataRecord.mc_number,
              dotNumber: carrierDataRecord.dot_number,
              phone: carrierDataRecord.phone,
              email: carrierDataRecord.email,
              blacklist:
                carrierDataRecord.blacklist === true ? "Inactive" : "Active",
            }
            carrierData.results.push(carrier)
          }
          commit("setCarrierCompanies", carrierData)
          commit("generic/setLoadingIcon", false, { root: true })
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve carriers.",
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  GET_FACTORING_COMPANY_PAYMENTS: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/billing/api/factoring-payment-list/${payload.id}/`,
          {
            params: payload.params,
          }
        )
        .then((response) => {
          const paymentData = {
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            results: [],
          }
          const data = response.data.results
          for (let i = 0; i < data.length; i++) {
            const paymentRecord = data[i]
            const payment = {
              id: paymentRecord.id,
              createdAt: paymentRecord.created_at,
              loadNumber: paymentRecord.load_id,
              loadStatus: paymentRecord.load_status,
              loadShipper: paymentRecord.load_shipper,
              loadConsignee: paymentRecord.load_consignee,
              loadEquipmentType: paymentRecord.load_equipment_type,
              loadPickupDate: paymentRecord.load_pickup_date,
              loadDeliveryDate: paymentRecord.load_delivery_date,
              carrierPay: paymentRecord.load_margin.carrier_total_price,
              customerPay: paymentRecord.load_margin.customer_total_price,
              margin: paymentRecord.load_margin.margin,
              carrierName: paymentRecord.carrier_name,
              carrierId: paymentRecord.carrier_id,
              customerName: paymentRecord.customer_name,
              checkId: paymentRecord.check_id,
              name: paymentRecord.name,
              description: paymentRecord.description,
              paymentMethod: paymentRecord.payment_method,
              paymentType: paymentRecord.payment_type,
              statusDisplay: paymentRecord.status_display,
              invoiceNumber: paymentRecord.invoice_number,
            }
            paymentData.results.push(payment)
          }
          commit("setFactoringPayments", paymentData)
          commit("generic/setLoadingIcon", false, { root: true })
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve factoring payments.",
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
}
const mutations = {
  setFactoringList(state, payload) {
    state.factoringList = payload
  },
  setCurrentFactoring(state, payload) {
    state.currentFactoring = payload
  },
  setCarrierCompanies(state, payload) {
    state.carrierCompanies = payload
  },
  setFactoringId(state, payload) {
    state.factoringId = payload
  },
  setFactoringPayments(state, payload) {
    state.factoringPayments = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
