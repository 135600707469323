const state = () => ({
  shipper: null,
  consignee: null,
  carriers: [],
  matchDetailDialog: false,
})

const getters = {
  carrierList: (state, getters) => {
    return state.carriers
  },
  matchDetailDialog: (state) => {
    return state.matchDetailDialog
  },
}

const actions = {
  SET_SHIPPER: ({ commit }, payload) => {
    commit("setShipper", payload)
  },
  SET_CONSIGNEE: ({ commit }, payload) => {
    commit("setConsignee", payload)
  },
  SET_CARRIER_LIST: ({ commit }, payload) => {
    commit("setCarrierList", payload)
  },
  EMPTY_CARRIER_LIST: ({ commit }) => {
    commit("emptyCarrierList")
  },
}

const mutations = {
  setShipper(state, payload) {
    state.shipper = payload
  },
  setConsignee(state, payload) {
    state.consignee = payload
  },
  setCarrierList(state, payload) {
    state.carriers = payload
  },
  emptyCarrierList(state) {
    state.carriers = []
  },
  setMatchDetailDialog(state, payload) {
    state.matchDetailDialog = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
