import Vue from "vue"
import Router from "vue-router"
import api from "@/api/api"

Vue.use(Router)

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/pages",
      component: () => import("@/views/layouts/clean/Index"),
      children: [
        {
          name: "Login",
          path: "login",
          component: () => import("@/views/pages/Login"),
        },
      ],
    },
    {
      name: "Index",
      path: "/",
      component: () => import("@/views/layouts/default/Index"),
      children: [
        {
          name: "Dashboard",
          path: "dashboard",
          component: () => import("@/views/pages/Dashboard"),
          props: true,
          meta: {
            title: "Dashboard",
          },
        },
        {
          name: "Carrier Sales Representative Dashboard",
          path: "dashboards/carrier-sales-rep",
          component: () =>
            import("@/views/pages/user-profiles/CarrierSalesRepDashboard"),
          props: true,
          meta: {
            title: "Carrier Sales Rep Dashboard",
          },
        },
        {
          name: "Location Detail",
          path: "locations/detail/:id",
          component: () => import("@/views/pages/LocationDetail"),
          props: true,
          meta: {
            title: "",
          },
        },
        {
          name: "Locations",
          path: "locations/list",
          component: () => import("@/views/pages/AllLocations"),
          meta: {
            title: "Locations",
          },
        },
        {
          name: "CSV Import",
          path: "csv-import",
          component: () => import("@/views/pages/csv-import/CsvImport"),
          meta: {
            title: "CSV Import",
          },
        },
        {
          name: "Customer Detail",
          path: "customers/detail/:id",
          component: () => import("@/views/pages/CustomerDetail"),
          props: true,
          meta: {
            title: "",
          },
        },
        {
          name: "Customers",
          path: "customers/list",
          component: () => import("@/views/pages/Customers"),
          meta: {
            title: "Customers",
          },
        },
        {
          name: "Spot Board",
          path: "shipments/spotboard",
          component: () => import("@/views/pages/Spotboard"),
        },
        {
          name: "Accepted Tenders",
          path: "shipments/accepted-tenders",
          component: () => import("@/views/pages/AcceptedTenders"),
        },
        {
          name: "Load Detail",
          path: "shipments/load-detail/:id",
          component: () => import("@/views/pages/LoadDetail"),
          props: true,
          meta: {
            title: "",
          },
        },
        {
          name: "Loads",
          path: "shipments/loads/",
          component: () => import("@/views/pages/AllLoads"),
          props: true,
          meta: {
            title: "Shipments List",
          },
        },
        {
          name: "Carriers",
          path: "carriers/list/",
          component: () => import("@/views/pages/Carriers"),
          props: true,
          meta: {
            title: "Carriers",
          },
        },
        {
          name: "Carrier Detail",
          path: "carriers/detail/:id",
          component: () => import("@/views/pages/CarrierDetail"),
          props: true,
          meta: {
            title: "",
          },
        },
        {
          name: "Receivables",
          path: "accounting/receivables/",
          component: () => import("@/views/pages/account/Receivables"),
          meta: {
            title: "Receivables",
          },
        },
        {
          name: "Payables",
          path: "accounting/payables/",
          component: () => import("@/views/pages/account/Payables"),
          meta: {
            title: "Payables",
          },
        },
        {
          name: "Load Payments",
          path: "accounting/load-payment/",
          component: () => import("@/views/pages/account/LoadPayment"),
          meta: {
            title: "Load Payments",
          },
        },
        {
          name: "Approved Payments",
          path: "accounting/approved-payment/",
          component: () => import("@/views/pages/account/ApprovedPayments"),
          meta: {
            title: "Approved Payments",
          },
        },
        {
          name: "Quotes",
          path: "shipments/quotes/",
          component: () => import("@/views/pages/AllQuotes"),
          props: true,
          meta: {
            title: "Quote List",
          },
        },
        {
          name: "Factoring Companies",
          path: "factoring/",
          component: () => import("@/views/pages/AllFactoringCompany"),
          props: true,
          meta: {
            title: "Factoring Companies",
          },
        },
        {
          name: "Factoring Detail",
          path: "factoring/detail/:id",
          component: () => import("@/views/pages/FactoringCompanyDetail"),
          props: true,
          meta: {
            title: "",
          },
        },
      ],
    },
    {
      path: "*",
      component: () => import("@/views/layouts/clean/Index"),
      children: [
        {
          name: "404 Error",
          path: "",
          component: () => import("@/views/pages/Error"),
        },
      ],
    },
  ],
})

api.interceptors.request.use((config) => {
  const token = router.app.$cookie.get("token")
  config.headers.common.Authorization = `Bearer ${token}`
  return config
})

router.beforeEach((to, from, next) => {
  const fromPath = router.app.$cookie.get("from_path")
  const token = router.app.$cookie.get("token")
  // save previous page path
  if (from.path && from.path !== "/") {
    router.app.$cookie.set("from_path", from.path)
  }
  // if user is not authorized redirect to Login page
  if (to.name !== "Login" && !token && !fromPath) {
    next({ name: "Login" })
  } else if (to.name !== "Login" && !token && to.fullPath) {
    next({
      name: "Login",
      query: { redirect: to.fullPath },
    })
  } else if (to.name !== "Login" && !token) {
    next({ name: "Login" })
  }
  // if user is autorized and goes to Index page regirect to Loads list
  else if (to.name === "Index") {
    next({ name: "Loads" })
  }
  // regular autorized user navigation
  else {
    next()
  }
})

export default router
