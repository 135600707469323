import Vue from "vue"
import Vuex from "vuex"

import main from "./modules/main"
import spotboard from "./modules/spotboard"
import create from "./modules/create"
import generic from "./modules/generic"
import loadDetail from "./modules/loadDetails"
import carrier from "./modules/carrier"
import location from "./modules/location"
import customer from "./modules/customer"
import carrierMatching from "@/store/modules/carrierMatching"
import metabase from "@/store/modules/metabase"
import csvImport from "@/store/modules/csvImport"
import billing from "@/store/modules/billing"
import factoring from "@/store/modules/factoring"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    main,
    spotboard,
    create,
    generic,
    loadDetail,
    carrier,
    location,
    customer,
    carrierMatching,
    metabase,
    csvImport,
    billing,
    factoring,
  },
})
