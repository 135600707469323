import api from "@/api/api"
import common from "@/utils/common"
const snackbarType = common.data.snackbarType
const state = () => ({
  currentCustomer: {},
  customerCompanies: {
    count: 0,
    next: null,
    previous: null,
    results: [
      {
        id: 0,
        name: null,
        parent: null,
        location: null,
        shipment: null,
        revenue: null,
      },
    ],
  },
  customerNotes: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  customerNoteDetail: {
    id: 0,
    createdAt: null,
    updatedAt: null,
    createdByDisplay: null,
    note: null,
  },
  customerDocuments: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  carrierDocument: {
    id: 0,
    createdAt: null,
    updatedAt: null,
    uploadedByDisplay: null,
    file: null,
    fileName: null,
    name: null,
    documentType: null,
    documentTypeDisplay: null,
  },
  uploadProgress: 0,
  customerContacts: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  customerStats: {
    totalShipment: 0,
    averageMargin: 0,
  },
  customerShipments: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  selectedTab: "tab-1",
})

const getters = {
  currentCustomer: (state) => {
    return state.currentCustomer
  },
  customerCompanies: (state) => {
    return state.customerCompanies
  },
  customerNotes: (state) => {
    return state.customerNotes
  },
  customerNoteDetail: (state) => {
    return state.customerNoteDetail
  },
  customerDocuments: (state) => {
    return state.customerDocuments
  },
  customerDocument: (state) => {
    return state.customerDocument
  },
  customerContacts: (state) => {
    return state.customerContacts
  },
  customerStats: (state) => {
    return state.customerStats
  },
  customerShipments: (state) => {
    return state.customerShipments
  },
  uploadProgress: (state) => {
    return state.uploadProgress
  },
  selectedTab: (state) => {
    return state.selectedTab
  },
}

const actions = {
  GET_CUSTOMER_COMPANY: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/customer-rud/${payload}/`
      )
      .then((response) => {
        const _customer = response.data
        const customerInfo = {
          id: _customer.id,
          active: _customer.active,
          name: _customer.name,
          accountOwner: _customer.account_owner,
          accountOwnerDisplay: _customer.account_owner_display,
          parent: _customer.parent,
          parentDisplay: _customer.parent_display,
          payTerms: _customer.pay_terms,
          organizationLevel: _customer.organization_level,
          organizationLevelDisplay: _customer.organization_level_display,
          website: _customer.website,
          phone: _customer.phone,
          address: _customer.address,
          city: _customer.city,
          state: _customer.state,
          usZipcode: _customer.us_zipcode,
          caProvince: _customer.ca_province,
          caPostalCode: _customer.ca_postal_code,
          country: _customer.country,
          totalShipments: _customer.total_shipments,
          totalRevenue: _customer.total_revenue,
          creditLimit: _customer.credit_limit,
          utilizedCredit: _customer.utilized_credit,
          quickbooksID: _customer.quickbooks_id,
          locations: _customer.locations,
          carriers: _customer.carriers,
          accountManager: _customer.account_manager,
          accountManagerDisplay: _customer.account_manager_display,
        }
        commit("setCurrentCustomer", customerInfo)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve customer.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CUSTOMER_COMPANY_LIST: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/list-create/`,
        {
          params: payload,
        }
      )
      .then((response) => {
        const customerData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const customerDataRecord = data[i]
          const customer = {
            id: customerDataRecord.id,
            name: customerDataRecord.name,
            accountOwner: customerDataRecord.account_owner,
            accountOwnerDisplay: customerDataRecord.account_owner_display,
            parent: customerDataRecord.parent,
            parentDisplay: customerDataRecord.parent_display,
            payTerms: customerDataRecord.pay_terms,
            organizationLevel: customerDataRecord.organization_level,
            organizationLevelDisplay:
              customerDataRecord.organization_level_display,
            website: customerDataRecord.website,
            phone: customerDataRecord.phone,
            address: customerDataRecord.address,
            city: customerDataRecord.city,
            state: customerDataRecord.state,
            usZipcode: customerDataRecord.us_zipcode,
            caProvince: customerDataRecord.ca_province,
            caPostalCode: customerDataRecord.ca_postal_code,
            country: customerDataRecord.country,
            totalShipments: customerDataRecord.total_shipments,
            totalRevenue: customerDataRecord.total_revenue,
            accountManager: customerDataRecord.account_manager,
            accountManagerDisplay: customerDataRecord.account_manager_display,
          }
          customerData.results.push(customer)
        }
        commit("setCustomerCompanies", customerData)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve customers.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CUSTOMER_NOTES: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/note/${payload}?limit=500`
      )
      .then((response) => {
        const noteData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const noteRecord = data[i]
          const note = {
            id: noteRecord.id,
            createdAt: noteRecord.created_at,
            updatedAt: noteRecord.updated_at,
            createdByDisplay: noteRecord.created_by_display,
            note: noteRecord.note,
          }
          noteData.results.push(note)
        }
        commit("setCustomerNotes", noteData)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Customer Notes.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CUSTOMER_NOTE_DETAIL: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/note-rud/${payload}/`
      )
      .then((response) => {
        const note = {
          id: response.data.id,
          createdAt: response.data.created_at,
          updatedAt: response.data.updated_at,
          createdByDisplay: response.data.created_by_display,
          note: response.data.note,
        }
        commit("setCustomerNoteDetail", note)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Customer Note.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  ADD_CUSTOMER_NOTE: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .post(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/note/${payload.id}/`,
        {
          note: payload.text,
        }
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Noted added successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_CUSTOMER_NOTES", payload.id)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to add note.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  UPDATE_CUSTOMER_NOTE: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .put(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/note-rud/${payload.id}/`,
        {
          note: payload.text,
        }
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Noted updated successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_CUSTOMER_NOTES", payload.customerId)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to update note.",
          type: snackbarType.error,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        commit("generic/setLoadingIcon", false, { root: true })
      })
  },
  DELETE_CUSTOMER_NOTE: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .delete(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/note-rud/${payload.id}/`
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Note deleted successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_CUSTOMER_NOTES", payload.customerId)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to delete note.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CUSTOMER_DOCUMENTS: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(payload)
      .then((response) => {
        const documentData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const documentRecord = data[i]
          const document = {
            id: documentRecord.id,
            createdAt: documentRecord.created_at,
            modifyDetail: {
              updatedAt: documentRecord.updated_at,
              uploadedByDisplay: documentRecord.uploaded_by_display,
            },
            file: documentRecord.file,
            fileName: documentRecord.file_name,
            name: documentRecord.name,
            documentType: documentRecord.document_type,
            documentTypeDisplay: documentRecord.document_type_display,
          }
          documentData.results.push(document)
        }
        commit("setCustomerDocuments", documentData)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Customer Documents.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CUSTOMER_DOCUMENT_DETAIL: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/document-rud/${payload}/`
      )
      .then((response) => {
        const document = {
          id: response.data.id,
          createdAt: response.data.created_at,
          updatedAt: response.data.updated_at,
          uploadedByDisplay: response.data.uploaded_by_display,
          file: response.data.file,
          fileName: response.data.file_name,
          name: response.data.name,
          documentType: response.data.document_type,
          documentTypeDisplay: response.data.document_type_display,
        }
        commit("setCustomerDocuments", document)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Customer Documents.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  ADD_CUSTOMER_DOCUMENT: ({ commit, dispatch }, payload) => {
    api
      .post(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/document/${payload.customerId}/`,
        payload.fmData,
        {
          onUploadProgress: function (progressEvent) {
            commit(
              "setUploadProgress",
              parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              )
            )
          },
        }
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "File added successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_CUSTOMER_DOCUMENTS", payload.customerDocumentsURL)
        commit("setUploadProgress", 0)
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to add file.",
          type: snackbarType.error,
        }
        commit("setUploadProgress", 0)
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  UPDATE_CUSTOMER_DOCUMENT: ({ commit, dispatch }, payload) => {
    api
      .put(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/document-rud/${payload.id}/`,
        payload.fmData,
        {
          onUploadProgress: function (progressEvent) {
            commit(
              "setUploadProgress",
              parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              )
            )
          },
        }
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "File updated successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_CUSTOMER_DOCUMENTS", payload.customerDocumentsURL)
        commit("setUploadProgress", 0)
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to update file.",
          type: snackbarType.error,
        }
        commit("setUploadProgress", 0)
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  DELETE_CUSTOMER_DOCUMENT: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .delete(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/document-rud/${payload.id}/`
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Document deleted successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        commit("generic/setLoadingIcon", false, { root: true })
        dispatch("GET_CUSTOMER_DOCUMENTS", payload.customerDocumentsURL)
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to delete document.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CUSTOMER_CONTACTS: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(payload)
      .then((response) => {
        const contactData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const contactRecord = data[i]
          const contact = {
            id: contactRecord.user_id,
            createdAt: contactRecord.created_at,
            updatedAt: contactRecord.updated_at,
            agentCompany: contactRecord.agent_company,
            name:
              contactRecord.first_name +
              (contactRecord.last_name ? " " + contactRecord.last_name : ""),
            phone: contactRecord.phone,
            email: contactRecord.email,
            isPrimary: contactRecord.is_primary,
            pickupNotifications: contactRecord.pickup_notifications,
            deliveryNotifications: contactRecord.delivery_notifications,
            driverInfoNotifications: contactRecord.driver_info_notifications,
            role: contactRecord.role,
          }
          contactData.results.push(contact)
        }
        commit("setCustomerContacts", contactData)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Customer Contacts.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  ADD_CUSTOMER_CONTACT: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .post(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/contact/${payload.customerId}/`,
        payload.contact
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Customer contact added successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: e.response.data,
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  UPDATE_CUSTOMER_CONTACT: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .put(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/contact-rud/${payload.contactId}/`,
        payload.contact
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Customer contact updated successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: e.response.data,
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  DELETE_CUSTOMER_CONTACT: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .delete(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/contact-rud/${payload.contactId}/`
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Customer contact deleted successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to delete customer contact.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CUSTOMER_STATS: ({ commit }, payload) => {
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/stats/${payload}/`
      )
      .then((response) => {
        const data = response.data
        if (data !== null) {
          const objStats = {
            totalShipment: data.total_shipments,
            averageMargin: data.average_margin,
          }
          commit("setCustomerStats", objStats)
        }
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Customer Stats.",
          type: snackbarType.error,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CUSTOMER_SHIPMENTS: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/historical-loads/${payload.customerId}/`,
        {
          params: payload.queryParams,
        }
      )
      .then((response) => {
        const shipmentData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const shipmentRecord = data[i]
          const shipment = {
            carrierSalesRep: shipmentRecord.carrier_sales_rep,
            id: shipmentRecord.id,
            carrierCompanyName: shipmentRecord.carrier_company_name,
            pickupDate: shipmentRecord.pickup_date,
            deliveryDate: shipmentRecord.delivery_date,
            customerReferenceId: shipmentRecord.customer_reference_id,
            poNumber: shipmentRecord.po_number,
            shipper: {
              city: shipmentRecord.shipper ? shipmentRecord.shipper.city : "",
              state: "",
              stateProvinceRegion: shipmentRecord.shipper
                ? shipmentRecord.shipper.state_province_region
                : "",
            },
            consignee: {
              city: shipmentRecord.consignee
                ? shipmentRecord.consignee.city
                : "",
              state: "",
              stateProvinceRegion: shipmentRecord.consignee
                ? shipmentRecord.consignee.state_province_region
                : "",
            },
            customerPrice: shipmentRecord.customer_price,
            carrierPrice: shipmentRecord.carrier_price,
            equipmentType: shipmentRecord.equipment_type,
            loadStatus: shipmentRecord.load_status,
            margin: shipmentRecord.percent_margin,
            archived: shipmentRecord.archived,
            archivedAt: shipmentRecord.archived_at,
          }
          shipmentData.results.push(shipment)
        }
        commit("setCustomerShipments", shipmentData)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Customer Shipments.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  UPDATE_CUSTOMER_COMPANY: ({ commit, dispatch }, payload) => {
    api
      .put(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/customer-rud/${payload.id}/`,
        payload.customer
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Customer updated successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_CUSTOMER_COMPANY", payload.id)
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to update customer.",
          type: snackbarType.error,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  CREATE_NEW_CUSTOMER({ commit }, payload) {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .post(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/customer/list-create/`,
        payload
      )
      .then((res) => {
        const snackbar = {
          status: true,
          text: "Customer created successfully",
          type: snackbarType.success,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Could not create Customer",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  REMOVE_TRUCKSTOP_POSTINGS({ commit }, payload) {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .post(
        `${process.env.VUE_APP_BASE_URL}/automation-rules/api/remove-truckstop-postings/${payload.customerId}/`,
        {
          remove_postings: payload.removePostings,
        }
      )
      .then((res) => {
        const snackbar = {
          status: true,
          text: "Remove truckstop posting successfully",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Could not remove truckstop posting",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
}

const mutations = {
  setCurrentCustomer(state, payload) {
    state.currentCustomer = payload
  },
  setCustomerCompanies(state, payload) {
    state.customerCompanies = payload
  },
  setCustomerNotes(state, payload) {
    state.customerNotes = payload
  },
  setCustomerNoteDetail(state, payload) {
    state.customerNoteDetail = payload
  },
  setCustomerDocuments(state, payload) {
    state.customerDocuments = payload
  },
  setUploadProgress(state, payload) {
    state.uploadProgress = payload
  },
  setCustomerContacts(state, payload) {
    state.customerContacts = payload
  },
  setCustomerStats(state, payload) {
    state.customerStats = payload
  },
  setCustomerShipments(state, payload) {
    state.customerShipments = payload
  },
  setCurrentTab(state, payload) {
    state.selectedTab = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
