import api from "@/api/api"
import moment from "moment"
import common from "@/utils/common"
const snackbarType = common.data.snackbarType
const state = () => ({
  receivableLoadList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  payableLoadList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  loadPaymentList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  approvedPaymentList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  dialogAREdit: false,
  selectedCarrier: null,
})
const getters = {
  receivableLoadList: (state) => {
    return state.receivableLoadList
  },
  payableLoadList: (state) => {
    return state.payableLoadList
  },
  loadPaymentList: (state) => {
    return state.loadPaymentList
  },
  approvedPaymentList: (state) => {
    return state.approvedPaymentList
  },
  dialogAREdit: (state) => {
    return state.dialogAREdit
  },
  selectedCarrier: (state) => {
    return state.selectedCarrier
  },
}
const actions = {
  RECEIVE_LOAD_PAYMENT: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .put(
          `${process.env.VUE_APP_BASE_URL}/billing/api/receive-load-payment/${payload.id}/`,
          payload.data
        )
        .then((response) => {
          if (response.status === 200) {
            resolve()
          }
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: e.response.data,
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  GET_RECEIVABLE_LOADS: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(`${process.env.VUE_APP_BASE_URL}/billing/api/load-receivables/`, {
        params: payload,
      })
      .then((response) => {
        const loadData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const loadDataRecord = data[i]
          const load = {
            loadNumber: loadDataRecord.id,
            loadStatus: loadDataRecord.load_status,
            customer: loadDataRecord.customer_company,
            csr: loadDataRecord.carrier_sales_rep,
            carrier: loadDataRecord.carrier,
            pickupDate: loadDataRecord.pickup_date,
            deliveryDate: loadDataRecord.delivery_date,
            shipper: loadDataRecord.shipper,
            consignee: loadDataRecord.consignee,
            equipment: loadDataRecord.equipment_type,
            posted: loadDataRecord.loadboard,
            deliveryEarlyTime: loadDataRecord.delivery_early_time,
            deliveryLateTime: loadDataRecord.delivery_late_time,
            pickupEarlyTime: loadDataRecord.pickup_early_time,
            pickupLateTime: loadDataRecord.pickup_late_time,
            customerReferenceId: loadDataRecord.customer_reference_id,
            archived: loadDataRecord.archived,
            archivedAt: loadDataRecord.archived_at,
            customerPrice: loadDataRecord.customer_price,
            fuelSurcharge: loadDataRecord.fuel_surcharge,
            customerTotalPrice: loadDataRecord.load_margin.customer_total_price,
          }
          loadData.results.push(load)
        }
        commit("setReceivableLoadList", loadData)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve receivable loads.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_PAYABLES_LOADS: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(`${process.env.VUE_APP_BASE_URL}/billing/api/load-payables/`, {
        params: payload,
      })
      .then((response) => {
        const loadData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const loadDataRecord = data[i]
          const carrierObject = loadDataRecord.carrier
          const load = {
            loadNumber: loadDataRecord.id,
            loadStatus: loadDataRecord.load_status,
            customer: loadDataRecord.customer_company,
            csr: loadDataRecord.carrier_sales_rep,
            carrier: loadDataRecord.carrier,
            carrierName:
              carrierObject !== null ? loadDataRecord.carrier.name : "",
            receivables:
              carrierObject !== null
                ? loadDataRecord.carrier.ar_name
                : "",
            address:
              carrierObject !== null
                ? loadDataRecord.carrier.ar_address
                : "",
            city:
              carrierObject !== null
                ? loadDataRecord.carrier.ar_city
                : "",
            state:
              carrierObject !== null
                ? loadDataRecord.carrier.ar_state
                : "",
            zipcode:
              carrierObject !== null
                ? loadDataRecord.carrier.ar_zipcode
                : "",
            phone:
              carrierObject !== null
                ? loadDataRecord.carrier.ar_phone
                : "",
            email:
              carrierObject !== null
                ? loadDataRecord.carrier.ar_email
                : "",
            equipment: loadDataRecord.equipment_type,
            posted: loadDataRecord.loadboard,
            customerReferenceId: loadDataRecord.customer_reference_id,
            archived: loadDataRecord.archived,
            customerPrice: loadDataRecord.customer_price,
            carrierPrice: loadDataRecord.carrier_price,
            accessorial: loadDataRecord.accessorial,
            loadMargin: loadDataRecord.load_margin,
            invoicedDate: moment(String(loadDataRecord.invoiced_date)).isValid()
              ? moment(String(loadDataRecord.invoiced_date)).format(
                  "MM-DD-YYYY"
                )
              : null,
            carrierInvoiceNumber: loadDataRecord.carrier_invoice_number,
            uploadFile: loadDataRecord.upload_file,
          }
          loadData.results.push(load)
        }
        commit("setPayableLoadList", loadData)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve payable loads.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  POST_CARRIER_PAYMENT({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/billing/api/load-carrier-payment/`,
          payload
        )
        .then((response) => {
          if (response.status === 200) {
            const snackbar = {
              status: true,
              text: response.data,
              type: snackbarType.success,
            }
            commit("main/SET_SNACKBAR", snackbar, { root: true })
            commit("generic/setLoadingIcon", false, { root: true })
            resolve()
          } else if (response.status === 201) {
            const snackbar = {
              status: true,
              text: response.data,
              type: snackbarType.error,
            }
            commit("main/SET_SNACKBAR", snackbar, { root: true })
            commit("generic/setLoadingIcon", false, { root: true })
            resolve()
          } else {
            const snackbar = {
              status: true,
              text: response.data,
              type: snackbarType.error,
            }
            commit("generic/setLoadingIcon", false, { root: true })
            commit("main/SET_SNACKBAR", snackbar, { root: true })
            reject(response.data)
          }
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: e.response.data,
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  GET_LOAD_PAYMENTS: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(`${process.env.VUE_APP_BASE_URL}/billing/api/load-payment/`, {
        params: payload,
      })
      .then((response) => {
        const loadData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const loadDataRecord = data[i]
          const load = {
            id: loadDataRecord.id,
            createdAt: moment(String(loadDataRecord.created_at)).isValid()
              ? moment(String(loadDataRecord.created_at)).format("MM-DD-YYYY")
              : null,
            loadId: loadDataRecord.load,
            carrier: loadDataRecord.carrier,
            carrierName: loadDataRecord.carrier.name,
            checkId: loadDataRecord.check_id,
            name: loadDataRecord.ar_name,
            amount: loadDataRecord.amount,
            description: loadDataRecord.description,
            paymentType: loadDataRecord.payment_type,
            paymentMethod: loadDataRecord.payment_method,
            paymentType: loadDataRecord.payment_type,
            status: loadDataRecord.status,
            statusDisplay: loadDataRecord.status_display,
            recipient: loadDataRecord.recipient,
            invoiceNumber: loadDataRecord.invoice_number,
            hasError: false,
            errorMessage: "",
          }
          loadData.results.push(load)
        }
        commit("setLoadPaymentList", loadData)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve payments.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  POST_PAYMENT_APPROVE({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("loadDetail/setLoadingIcon", true, { root: true })
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/billing/api/check-approve/`,
          payload
        )
        .then((response) => {
          if (response.status === 200) {
            commit("generic/setLoadingIcon", false, { root: true })
            resolve(response.data)
          }
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: e.response.data,
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  GET_APPROVED_PAYMENTS: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/billing/api/load-carrier-payment-approve/`,
        {
          params: payload,
        }
      )
      .then((response) => {
        const loadData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const loadDataRecord = data[i]
          const load = {
            id: loadDataRecord.id,
            createdAt: moment(String(loadDataRecord.created_at)).isValid()
              ? moment(String(loadDataRecord.created_at)).format("MM-DD-YYYY")
              : null,
            loadId: loadDataRecord.load,
            carrier: loadDataRecord.carrier,
            carrierName: loadDataRecord.carrier.name,
            checkId: loadDataRecord.check_id,
            name: loadDataRecord.ar_name,
            amount: loadDataRecord.amount,
            description: loadDataRecord.description,
            paymentType: loadDataRecord.payment_type,
            paymentMethod: loadDataRecord.payment_method,
            status: loadDataRecord.status,
            statusDisplay: loadDataRecord.status_display,
            recipient: loadDataRecord.recipient,
            invoiceNumber: loadDataRecord.invoice_number,
          }
          loadData.results.push(load)
        }
        commit("setApprovedPaymentList", loadData)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve approved payments.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  POST_CHECK_VOID({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/billing/api/check-void/`,
          payload
        )
        .then((response) => {
          const snackbar = {
            status: true,
            text: response.data,
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("generic/setLoadingIcon", false, { root: true })
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: e.response.data,
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  INVOICE_LOAD({ commit }, payload) {
    return new Promise((resolve) => {
      commit("loadDetail/setLoadingIcon", true, { root: true })
      const data = {
        initiate_billing: true,
      }
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/billing/api/initiate-billing/${payload}/`,
          data
        )
        .then((response) => {
          commit("loadDetail/setLoadingIcon", false, { root: true })
          const snackbar = {
            status: true,
            text: response.data,
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: e.response.data,
            type: snackbarType.error,
          }
          commit("loadDetail/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
        })
    })
  },
}
const mutations = {
  setReceivableLoadList(state, payload) {
    state.receivableLoadList = payload
  },
  setPayableLoadList(state, payload) {
    state.payableLoadList = payload
  },
  setLoadPaymentList(state, payload) {
    state.loadPaymentList = payload
  },
  setApprovedPaymentList(state, payload) {
    state.approvedPaymentList = payload
  },
  setDialogAREdit(state, payload) {
    state.dialogAREdit = payload
  },
  setSelectedCarrier(state, payload) {
    state.selectedCarrier = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
