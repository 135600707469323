import api from "@/api/api"
import common from "@/utils/common"
const snackbarType = common.data.snackbarType

const state = () => ({
  capacityDialog: false,
  locationDialog: false,
  carrierDialog: false,
  customerDialog: false,
  loadDialog: false,
  factoringDialog: false,
  capacity: {},
  location: {},
  carrier: {},
})

const getters = {
  capacityDialog: (state, getters) => {
    return state.capacityDialog
  },
  locationDialog: (state) => {
    return state.locationDialog
  },
  carrierDialog: (state) => {
    return state.carrierDialog
  },
  customerDialog: (state) => {
    return state.customerDialog
  },
  loadDialog: (state) => {
    return state.loadDialog
  },
  factoringDialog: (state) => {
    return state.factoringDialog
  },
}

const actions = {
  SET_DIALOG: ({ commit }, payload) => {
    commit("setDialog", payload)
  },
  CREATE_CARRIER({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit("setCarrier", payload)
      const postData = {
        name: state.carrier.name,
        mc_number: state.carrier.mcNumber,
        dot_number: state.carrier.dotNumber,
        phone: state.carrier.phone,
        email: state.carrier.email,
        network: state.carrier.network,
      }
      if (state.carrier.customerCompany) {
        postData.customer_company_id = state.carrier.customerCompany
      }
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/list-create/`,
          postData
        )
        .then((res) => {
          commit("setCarrierDialog", false)
          const payload = {
            status: true,
            text: "Carrier created.",
            response: res.response,
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", payload, { root: true })
          resolve()
        })
        .catch((e) => {
          const payload = {
            status: true,
            text:
              e.response.data?.name?.[0] ||
              "Could not create carrier, it may already exist.",
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", payload, { root: true })
          reject(e.response)
        })
    })
  },
  CREATE_LOCATION({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit("setLocation", payload)
      const postData = {
        name: state.location.name,
        contact_name: state.location.contactName,
        contact_phone: state.location.contactPhone,
        hours: state.location.hours,
        notes: state.location.notes,
        current_place: state.location.currentPlace,
        latitude: state.location.latitude,
        longitude: state.location.longitude,
      }
      if (state.location.customerCompany) {
        postData.customer_company_id = state.location.customerCompany
      }
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/locations/api/list-create/`,
          postData
        )
        .then((res) => {
          commit("setLocationDialog", false)
          const payload = {
            status: true,
            text: "Location created.",
            response: res.response,
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", payload, { root: true })
          resolve()
        })
        .catch((e) => {
          const payload = {
            status: true,
            text: "Could not create Location",
            error: e.response.data,
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", payload, { root: true })
          reject(e.response)
        })
    })
  },
  CREATE_CAPACITY({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit("setCapacity", payload)
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/carrier-matching/api/create-capacity/`,
          {
            carrier_company:
              state.capacity.carrier !== null
                ? state.capacity.carrier.id
                : null,
            src_location: state.capacity.sourceLocation
              ? {
                  latitude: state.capacity.sourceLocation.latitude,
                  longitude: state.capacity.sourceLocation.longitude,
                  current_place: state.capacity.sourceLocation.currentPlace,
                }
              : null,
            dst_location: state.capacity.destinationLocation
              ? {
                  latitude: state.capacity.destinationLocation.latitude,
                  longitude: state.capacity.destinationLocation.longitude,
                  current_place:
                    state.capacity.destinationLocation.currentPlace,
                }
              : null,
            src_date: state.capacity.sourceDate,
            quantity_trucks: state.capacity.qtyTrucks,
            quantity_loads: state.capacity.qtyLoads,
            equipment_type: state.capacity.equipmentType,
            carrier_bid: state.capacity.rate,
            source: state.capacity.source,
            match_type: state.capacity.matchType,
            quote_id: state.capacity.quoteId,
          }
        )
        .then((res) => {
          commit("setCapacityDialog", false)
          const payload = {
            status: true,
            text: "Capacity created.",
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", payload, { root: true })
          resolve(res)
        })
        .catch((e) => {
          const payload = {
            status: true,
            text: "Could not create Capacity",
            error: e.response.data,
          }
          commit("main/SET_SNACKBAR", payload, { root: true })
          reject(e.response.data)
        })
    })
  },
  CREATE_QUOTE({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit("setCapacity", payload)
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/carrier-matching/api/create-quote/${payload.loadId}/`,
          {
            carrier_bid: payload.carrierBid,
            source: payload.source,
            carrier_information: {
              name: payload.carrierName,
              email: payload.email,
              phone: payload.carrierPhone,
              dot_number: payload.dotNumber,
              mc_number: payload.mcNumber,
              dispatch_name: payload.dispatchName,
            },
          }
        )
        .then((res) => {
          const message = {
            status: true,
            text: "Capacity quote created.",
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", message, { root: true })
          resolve(res.data)
        })
        .catch((e) => {
          const message = {
            status: true,
            text: "Could not create Quote",
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", message, { root: true })
          reject(e.response.data)
        })
    })
  },
  CREATE_FACTORING_COMPANY: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/accounts/api/factoring/list-create/`,
          payload
        )
        .then((response) => {
          const snackbar = {
            status: true,
            text: "Factoring company created successfully",
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("generic/setLoadingIcon", false, { root: true })
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: e.response.data,
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
}

const mutations = {
  setCapacityDialog(state, payload) {
    state.capacityDialog = payload
  },
  setCarrierDialog(state, payload) {
    state.carrierDialog = payload
  },
  setLocationDialog(state, payload) {
    state.locationDialog = payload
  },
  setCustomerDialog(state, payload) {
    state.customerDialog = payload
  },
  setLoadDialog(state, payload) {
    state.loadDialog = payload
  },
  setCapacityLocation(state, payload) {
    const direction = payload.direction
    if (direction === "source") {
      state.location.sourceLocation = payload.location
    }
    if (direction === "dest") {
      state.location.destinationLocation = payload.location
    }
  },
  setCapacity(state, payload) {
    state.capacity = payload
  },
  setLocation(state, payload) {
    state.location = payload
  },
  setCarrier(state, payload) {
    state.carrier = payload
  },
  setFactoringDialog(state, payload) {
    state.factoringDialog = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
