import _camelCase from "lodash/camelCase"
import _mapKeys from "lodash/mapKeys"

export default {
  data: {
    equipmentType: null,
    snackbarType: {
      error: "error",
      success: "success",
      info: "info",
      warning: "warning",
    },
    mapType: {
      googleMap: 1,
      hereMap: 2,
    },
  },
  methods: {
    setPageTitle(title) {
      document.title = title
    },
    formatDate(date) {
      if (date) {
        const split = date.split(/(?:T| )+/)

        const datePart = split[0]
        const dateItems = datePart.split("-")
        const timePart = split[1]
        return (
          dateItems[1] +
          "-" +
          dateItems[2] +
          "-" +
          dateItems[0] +
          " " +
          timePart
        )
      }
    },
    setWithExpiry(key, value, ttl) {
      const now = new Date()
      const item = {
        value: value,
        expiry: now.getTime() + ttl * 1000,
      }
      localStorage.setItem(key, JSON.stringify(item))
    },
    getWithExpiry(key) {
      const itemStr = localStorage.getItem(key)
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null
      }
      const item = JSON.parse(itemStr)
      const now = new Date()
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
      }
      return item.value
    },
    clearAllLocalStorage() {
      localStorage.removeItem("equipment_types")
      localStorage.removeItem("mode_types")
      localStorage.removeItem("order_types")
      localStorage.removeItem("insurance_types")
      localStorage.removeItem("load_status_choices")
      localStorage.removeItem("countries_list")
    },
    formatLocation(city = "", state) {
      const capitalize = (string) =>
        string.charAt(0).toUpperCase() + string.slice(1)

      return `${city ? capitalize(city) : ""}${city && state ? ", " : ""}${
        state ? state.toUpperCase() : ""
      }`
    },
    /**
     * Function for disable or hide button based on login user role and load status
     * @param shipmentDetail - Load's shipment detail object
     * @param agentDetail - Login user's object
     * @returns {boolean} - True or False (True means user don't have access and False means user have access)
     */
    disableButtonBaseOnLoadStatus(shipmentDetail, agentDetail) {
      const restrictedStatus = [
        14,
        8,
        9,
        18,
        "Ready To Bill",
        "Invoiced",
        "Paid",
        "Received",
      ]
      if (shipmentDetail.archived || shipmentDetail.hold) {
        return true
      } else {
        if (restrictedStatus.includes(shipmentDetail.loadStatus)) {
          if (agentDetail.accounting || agentDetail.admin) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      }
    },
    toCamelCase(obj) {
      if (Array.isArray(obj)) {
        return obj.map((item) => this.toCamelCase(item))
      } else {
        return _mapKeys(obj, (_, key) => _camelCase(key))
      }
    }
  },
}
