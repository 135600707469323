import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "./plugins/vee-validate"
import "./plugins/v-mask"
import "./plugins/vue-world-map"
import vuetify from "./plugins/vuetify"
import i18n from "./i18n"
import * as VueGoogleMaps from "vue2-google-maps"
import page from "v-page"
import "./assets/colors.css"
import "./assets/constants.css"
import "./assets/helpers.css"
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"

const VueCookie = require("vue-cookie")

if (process.env.VUE_APP_ENVIRONMENT !== 'local' && process.env.NODE_ENV !== 'test') {
  Sentry.init({
    Vue,
    dsn: "https://48a1e042387a46e3b15d35e47ff6794e@o1296226.ingest.sentry.io/6536974",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["tms.staging.exofreight.com", "tms.exofreight.com", /^\//],
      }),
    ],
    tracesSampleRate: 0.7,
    environment: process.env.NODE_ENV
  });
}

// Tell Vue to use the plugin
Vue.use(VueCookie)
Vue.use(page, {
  language: "en",
  align: "right",
  border: false,
})

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: "places, directions",
  },
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app")
