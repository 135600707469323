import api from "@/api/api"
import common from "@/utils/common"
const snackbarType = common.data.snackbarType

const state = {
  currentLocation: {},
  uploadProgress: 0,
  locationShipments: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  locationDocuments: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  locationDocument: {
    id: 0,
    createdAt: null,
    updatedAt: null,
    uploadedByDisplay: null,
    file: null,
    fileName: null,
    name: null,
    documentType: null,
    documentTypeDisplay: null,
  },
  locationNotes: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  locationNoteDetail: {
    id: 0,
    createdAt: null,
    updatedAt: null,
    createdByDisplay: null,
    note: null,
  },
  locations: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
}

const getters = {
  currentLocation: (state) => {
    return state.currentLocation
  },
  locationShipments: (state) => {
    return state.locationShipments
  },
  uploadProgress: (state) => {
    return state.uploadProgress
  },
  locationDocuments: (state) => {
    return state.locationDocuments
  },
  locationDocument: (state) => {
    return state.locationDocument
  },
  locationNotes: (state) => {
    return state.locationNotes
  },
  locationNoteDetail: (state) => {
    return state.locationNoteDetail
  },
  locations: (state) => {
    return state.locations
  },
}

const actions = {
  GET_LOCATION: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/locations/api/detail/${payload.locationId}/`
      )
      .then((response) => {
        const location = response.data
        const locationInfo = {
          id: location.id,
          name: location.name,
          address: location.address,
          city: location.city,
          state: location.state,
          stateProvinceRegion: location.state_province_region,
          postalCode: location.postal_code,
          zipcode: location.us_zipcode,
          caProvince: location.ca_province,
          caPostalCode: location.ca_postal_code,
          country: location.country,
          contactName: location.contact_name,
          contactPhone: location.contact_phone,
          hours: location.hours,
          notes: location.notes,
          geoPoint: location.geo_point,
          earlyTime: location.early_time,
          lateTime: location.late_time,
          latitude: location.latitude,
          longitude: location.longitude,
          customerCompanies: location.customer_companies,
        }
        commit("setCurrentLocation", locationInfo)
        if (payload.formType === 2) {
          commit("loadDetail/setShipperInfo", locationInfo, {
            root: true,
          })
        }
        if (payload.formType === 3) {
          commit("loadDetail/setConsigneeInfo", locationInfo, {
            root: true,
          })
        }
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve location.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  UPDATE_LOCATION: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .put(
        `${process.env.VUE_APP_BASE_URL}/locations/api/detail/${payload.locationId}/`,
        payload.data
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Location updated successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_LOCATION", payload)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to update location.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_LOCATION_SHIPMENTS: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/locations/api/shipment-list/${payload.locationId}/`,
        {
          params: payload.queryParams,
        }
      )
      .then((response) => {
        const shipmentData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const shipmentRecord = data[i]
          const shipment = {
            id: shipmentRecord.id,
            loadStatus: shipmentRecord.load_status,
            shipper: {
              city: shipmentRecord.shipper.city,
              stateProvinceRegion: shipmentRecord.shipper.state_province_region,
            },
            pickupDate: shipmentRecord.pickup_date,
            consignee: {
              city: shipmentRecord.consignee.city,
              stateProvinceRegion:
                shipmentRecord.consignee.state_province_region,
            },
            deliveryDate: shipmentRecord.delivery_date,
            customerPrice: shipmentRecord.customer_price,
            carrierPrice: shipmentRecord.carrier_price,
            percentMargin: shipmentRecord.percent_margin,
            equipmentType: shipmentRecord.equipment_type,
            archived: shipmentRecord.archived,
            archivedAt: shipmentRecord.archived_at,
          }
          shipmentData.results.push(shipment)
        }
        commit("setLocationShipments", shipmentData)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve shipments.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_LOCATION_DOCUMENTS: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(payload)
      .then((response) => {
        const documentData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const documentRecord = data[i]
          const document = {
            id: documentRecord.id,
            createdAt: documentRecord.created_at,
            modifyDetail: {
              updatedAt: documentRecord.updated_at,
              uploadedByDisplay: documentRecord.uploaded_by_display,
            },
            file: documentRecord.file,
            fileName: documentRecord.file_name,
            name: documentRecord.name,
          }
          documentData.results.push(document)
        }
        commit("setLocationDocuments", documentData)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Location Documents.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_LOCATION_DOCUMENT_DETAIL: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/locations/api/document-rud/${payload}/`
      )
      .then((response) => {
        const document = {
          id: response.data.id,
          createdAt: response.data.created_at,
          updatedAt: response.data.updated_at,
          uploadedByDisplay: response.data.uploaded_by_display,
          file: response.data.file,
          fileName: response.data.file_name,
          name: response.data.name,
          documentType: response.data.document_type,
          documentTypeDisplay: response.data.document_type_display,
        }
        commit("setLocationDocument", document)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve location Notes.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  ADD_LOCATION_DOCUMENT: ({ commit, dispatch }, payload) => {
    api
      .post(
        `${process.env.VUE_APP_BASE_URL}/locations/api/document/${payload.id}/`,
        payload.fmData,
        {
          onUploadProgress: function (progressEvent) {
            commit(
              "setUploadProgress",
              parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              )
            )
          },
        }
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "File added successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_LOCATION_DOCUMENTS", payload.locationURL)
        commit("setUploadProgress", 0)
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to add file.",
          type: snackbarType.error,
        }
        commit("setUploadProgress", 0)
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  UPDATE_LOCATION_DOCUMENT: ({ commit, dispatch }, payload) => {
    api
      .put(
        `${process.env.VUE_APP_BASE_URL}/locations/api/document-rud/${payload.id}/`,
        payload.fmData,
        {
          onUploadProgress: function (progressEvent) {
            commit(
              "setUploadProgress",
              parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              )
            )
          },
        }
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "File updated successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_LOCATION_DOCUMENTS", payload.locationURL)
        commit("setUploadProgress", 0)
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to update file.",
          type: snackbarType.error,
        }
        commit("setUploadProgress", 0)
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  DELETE_LOCATION_DOCUMENT: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .delete(
        `${process.env.VUE_APP_BASE_URL}/locations/api/document-rud/${payload.id}/`
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Document deleted successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_LOCATION_DOCUMENTS", payload.locationURL)
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to delete document.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_LOCATION_NOTES: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/locations/api/note/${payload}?limit=500`
      )
      .then((response) => {
        const noteData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const noteRecord = data[i]
          const note = {
            id: noteRecord.id,
            createdAt: noteRecord.created_at,
            updatedAt: noteRecord.updated_at,
            createdByDisplay: noteRecord.created_by_display,
            note: noteRecord.note,
          }
          noteData.results.push(note)
        }
        commit("setLocationNotes", noteData)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Location Notes.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_LOCATION_NOTE_DETAIL: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(`${process.env.VUE_APP_BASE_URL}/locations/api/note-rud/${payload}/`)
      .then((response) => {
        const note = {
          id: response.data.id,
          createdAt: response.data.created_at,
          updatedAt: response.data.updated_at,
          createdByDisplay: response.data.created_by_display,
          note: response.data.note,
        }
        commit("setLocationNoteDetail", note)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Location Notes.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  ADD_LOCATION_NOTE: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .post(
        `${process.env.VUE_APP_BASE_URL}/locations/api/note/${payload.id}/`,
        {
          note: payload.text,
        }
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Noted added successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_LOCATION_NOTES", payload.id)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to add note.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  UPDATE_LOCATION_NOTE: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .put(
        `${process.env.VUE_APP_BASE_URL}/locations/api/note-rud/${payload.id}/`,
        {
          note: payload.text,
        }
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Noted updated successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_LOCATION_NOTES", payload.locationId)
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to update note.",
          type: snackbarType.error,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        commit("generic/setLoadingIcon", false, { root: true })
      })
  },
  DELETE_LOCATION_NOTE: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .delete(
        `${process.env.VUE_APP_BASE_URL}/locations/api/note-rud/${payload.id}/`
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Note deleted successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_LOCATION_NOTES", payload.locationId)
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to delete note.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_LOCATIONS: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(`${process.env.VUE_APP_BASE_URL}/locations/api/list-create`, {
        params: payload,
      })
      .then((response) => {
        const locationData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const locationRecord = data[i]
          const location = {
            id: locationRecord.id,
            name: locationRecord.name,
            city: locationRecord.city,
            stateProvinceRegion: locationRecord.state_province_region,
            shipmentCount: locationRecord.shipment_count,
          }
          locationData.results.push(location)
        }
        commit("setLocations", locationData)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve locations.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  UPDATE_CUSTOMER_LOCATION: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .put(
          `${process.env.VUE_APP_BASE_URL}/locations/api/location-customer-relationship-rud/`,
          {
            location_id: payload.id,
            customer_company_id: payload.customerCompany,
          }
        )
        .then((response) => {
          const snackbar = {
            status: true,
            text: response.data,
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          resolve(payload)
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: e.response.data,
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("generic/setLoadingIcon", false, { root: true })
          reject(e.response)
        })
    })
  },
  DELETE_CUSTOMER_FROM_LOCATION: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .delete(
          `${process.env.VUE_APP_BASE_URL}/locations/api/location-customer-relationship-rud/`,
          {
            data: {
              location_id: payload.id,
              customer_company_id: payload.customerCompany,
            },
          }
        )
        .then((response) => {
          const snackbar = {
            status: true,
            text: response.data,
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: e.response.data,
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("generic/setLoadingIcon", false, { root: true })
          reject(e.response)
        })
    })
  },
}

const mutations = {
  setCurrentLocation(state, payload) {
    state.currentLocation = payload
  },
  setLocationShipments(state, payload) {
    state.locationShipments = payload
  },
  setLocationDocuments(state, payload) {
    state.locationDocuments = payload
  },
  setLocationDocument(state, payload) {
    state.locationDocument = payload
  },
  setUploadProgress(state, payload) {
    state.uploadProgress = payload
  },
  setLocationNotes(state, payload) {
    state.locationNotes = payload
  },
  setLocationNoteDetail(state, payload) {
    state.locationNoteDetail = payload
  },
  setLocations(state, payload) {
    state.locations = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
