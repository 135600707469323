import api from "@/api/api"
import moment from "moment"
import momentTimezone from "moment-timezone"
import common from "@/utils/common"
import loadboards from "./loadboards"

const snackbarType = common.data.snackbarType
const auditLogDefaultState = {
  count: 0,
  next: "",
  previous: "",
  results: [],
}

const state = () => ({
  autoPriceEnabled: false,
  loadId: null,
  loadPayload: null,
  shipmentDetailEditing: false,
  commodityAndDimensionsInfoEdit: false,
  tenderDetailEditing: false,
  loadCustomerAccessorialList: [],
  loadCarrierAccessorialList: [],
  shipmentDetail: {
    customer: {
      name: null,
    },
    agent: {},
    orderType: {
      id: null,
      name: "None",
    },
    loadStatus: null,
    equipmentType: null,
    carrier: null,
  },
  shipperInfo: {
    id: null,
    name: null,
    address: null,
    city: null,
    state: null,
    zipcode: null,
    caProvince: null,
    caPostalCode: null,
    country: null,
    contactName: null,
    contactPhone: null,
    hours: null,
    notes: null,
    geoPoint: null,
    earlyTime: null,
    lateTime: null,
    latitude: null,
    longitude: null,
  },
  loadInfo: {
    shipperApptTime: null,
    consigneeApptTime: null,
    shipperEarlyTime: null,
    consigneeEarlyTime: null,
    shipperPickupNumber: null,
    deliveryNumber: null,
    shipperLateTime: null,
    consigneeLateTime: null,
    loadboardPostStatuses: null,
  },
  shipperDetailEditing: false,
  consigneeInfo: {
    id: null,
    name: null,
    address: null,
    city: null,
    state: null,
    zipcode: null,
    caProvince: null,
    caPostalCode: null,
    country: null,
    contactName: null,
    contactPhone: null,
    hours: null,
    notes: null,
    geoPoint: null,
    earlyTime: null,
    lateTime: null,
    latitude: null,
    longitude: null,
  },
  consigneeDetailEditing: false,
  carrierInfo: null,
  carrierInfoEditing: false,
  loadDates: {
    dateCreated: null,
    bookedDate: null,
    confirmedDate: null,
    assignedAt: null,
    pickedDate: null,
    deliveredDate: null,
    updatedAt: null,
    invoicedDate: null,
    paidDate: null,
    archivedAt: null,
    dueDateEarly: null,
    dueDateLate: null,
  },
  commodityInfo: {
    loadLength: null,
    loadHeight: null,
    loadWidth: null,
    loadWeight: null,
    description: null,
    commodity: null,
    miles: null,
    quantity: null,
    declaredValue: null,
  },
  tenderDetails: {
    customerBase: 0.0,
    loadMargin: 0.0,
    percentMargin: 0,
    carrierStartBuy: 0.0,
    carrierMaxBuy: 0.0,
    carrierLineHaul: 0.0,
    fuelSurcharge: 0.0,
    carrierAccessorial: 0.0,
    customerTotalPrice: 0.0,
    carrierTotalPrice: 0.0,
    carrierTotalAccessorials: 0.0,
    customerTotalAccessorials: 0.0,
  },
  shipperId: null,
  consigneeId: null,
  smsMessages: [],
  smsTemplate: [
    {
      id: 0,
      name: null,
      description: null,
      messageLong: null,
      messageShort: null,
    },
  ],
  fileList: [],
  noteList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  auditLog: auditLogDefaultState,
  uploadProgress: 0,
  fileInput: null,
  loadList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  loadStatusChoices: [],
  loadingIcon: false,
  truckStopPrices: null,
  isLoadTrackingDialogOpen: false,
  loadTrackingDialogId: null,
  trackingUpdate: {
    pickedAt: null,
    deliveredAt: null,
    onTimePick: false,
    onTimeDelivery: false,
    pickupApptAt: null,
    deliveryApptAt: null,
    arrivedAtShipperAt: null,
    arrivedAtConsigneeAt: null,
  },
  loadStatusUpdate: null,
  reloadMap: true,
  addStopDialog: false,
  loadStopDetail: null,
  loadStopId: null,
  temperature: null,
})

const getters = {
  loadId: (state) => {
    return state.loadId
  },
  autoPriceEnabled: (state) => {
    return state.autoPriceEnabled
  },
  commodityAndDimensionsInfoEdit: (state) => {
    return state.commodityAndDimensionsInfoEdit
  },
  loadCustomerAccessorialList: (state) => {
    return state.loadCustomerAccessorialList
  },
  loadCarrierAccessorialList: (state) => {
    return state.loadCarrierAccessorialList
  },
  shipmentDetail: (state) => {
    return state.shipmentDetail
  },
  shipmentDetailEdit: (state) => {
    return state.shipmentDetailEditing
  },
  shipperInfo: (state) => {
    return state.shipperInfo
  },
  loadInfo: (state) => {
    return state.loadInfo
  },
  shipperDetailEdit: (state) => {
    return state.shipperDetailEditing
  },
  consigneeInfo: (state) => {
    return state.consigneeInfo
  },
  consigneeDetailEdit: (state) => {
    return state.consigneeDetailEditing
  },
  carrierInfo: (state) => {
    return state.carrierInfo
  },
  carrierInfoEdit: (state) => {
    return state.carrierInfoEditing
  },
  commodityInfo: (state) => {
    return state.commodityInfo
  },
  loadDates: (state) => {
    return state.loadDates
  },
  tenderDetails: (state) => {
    return state.tenderDetails
  },
  tenderDetailEditing: (state) => {
    return state.tenderDetailEditing
  },
  shipperInfoById: (state) => {
    return state.shipperInfo.filter((x) => x.Id === state.shipperId)[0]
  },
  shipperId: (state) => {
    return state.shipperId
  },
  consigneeId: (state) => {
    return state.consigneeId
  },
  smsMessages: (state) => {
    return state.smsMessages
  },
  smsTemplate: (state) => {
    return state.smsTemplate
  },
  fileList: (state) => {
    return state.fileList
  },
  noteList: (state) => {
    return state.noteList
  },
  auditLog: (state) => {
    return state.auditLog
  },
  uploadProgress: (state) => {
    return state.uploadProgress
  },
  fileInput: (state) => {
    return state.fileInput
  },
  loadList: (state) => {
    return state.loadList
  },
  loadStatusChoices: (state) => {
    return state.loadStatusChoices
  },
  loadingIcon: (state) => {
    return state.loadingIcon
  },
  truckStopPrices: (state) => {
    return state.truckStopPrices
  },
  isLoadTrackingDialogOpen: (state) => {
    return state.isLoadTrackingDialogOpen
  },
  trackingUpdate: (state) => {
    return state.trackingUpdate
  },
  loadTrackingDialogId: (state) => {
    return state.loadTrackingDialogId
  },
  loadStatusUpdate: (state) => {
    return state.loadStatusUpdate
  },
  reloadMap: (state) => {
    return state.reloadMap
  },
  addStopDialog: (state) => {
    return state.addStopDialog
  },
  loadStopDetail: (state) => {
    return state.loadStopDetail
  },
  loadStopId: (state) => {
    return state.loadStopId
  },
}

const actions = {
  PATCH_LOAD_DETAIL: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingIcon", true)
      const loadId = payload.id
      delete payload.id
      api
        .patch(
          `${process.env.VUE_APP_BASE_URL}/loads/api/load-update/${loadId}/`,
          payload
        )
        .then(() => {
          const snackbar = {
            status: true,
            type: snackbarType.success,
            text: "Load has been updated.",
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          dispatch("GET_LOAD_DETAIL", loadId)
          commit("setLoadingIcon", false)
          resolve()
        })
        .catch((error) => {
          let message, response = error.response?.data
          if (typeof response === "string") {
            message = response
          } else if (typeof response?.error === "string") {
            message = response.error
          } else {
            message = "Failed to update load."
          }
          const snackbar = {
            status: true,
            text: message,
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("setLoadingIcon", false)
          reject(error)
        })
    })
  },
  GET_LOAD_DETAIL: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/loads/api/load-detail/${payload}/`
        )
        .then((response) => {
          const data = response.data
          const loadStopData = data.loadstop_set
          const loadStops = []
          for (let i = 0; i < loadStopData.length; i++) {
            loadStops.push({
              id: loadStopData[i].id,
              locationName: loadStopData[i].location_name,
              location: loadStopData[i].location,
              stopType: loadStopData[i].stop_type,
              stopDate: moment(String(loadStopData[i].stop_date)).format(
                "MM-DD-YYYY"
              ),
              locationLatitude: loadStopData[i].latitude,
              locationLongitude: loadStopData[i].longitude,
            })
          }

          const dateSourceFormat = "YYYY-MM-DD[T]HH:mm:ss.SSSSSS'Z'"
          const autoPriceEnabled = data.auto_price_enabled || false
          commit("setAutoPriceEnabled", autoPriceEnabled)
          const shipDetail = {
            pickupDate: data.pickup_date,
            deliveryDate: data.delivery_date,
            id: data.id,
            customer: data.customer_company,
            equipmentType: data.equipment_type,
            agentCompany: data.agent_company,
            orderType: data.order_type,
            orderTypeDisplay: data.order_type_display,
            poNumber: data.po_number,
            puNumber: data.pu_number,
            agent: data.agent,
            carrierSalesRep: data.carrier_sales_rep,
            loadStatus: data.load_status,
            loadStatusDisplay: data.load_status_display,
            dispatchPhone: data.dispatch_phone,
            driverPhone: data.driver_phone,
            dispatchEmail: data.dispatch_email,
            dispatcher: data.dispatch_name,
            driverName: data.driver_name,
            loadboard: data.loadboard,
            archived: data.archived,
            archivedAt: data.archived_at,
            customerReferenceId: data.customer_reference_id,
            mode: data.mode,
            modeDisplay: data.mode_display,
            carrierInvoiceNumber: data.carrier_invoice_number,
            loadStops: loadStops,
            hold: data.hold,
            paidDate: moment(String(data.paid_date), dateSourceFormat).isValid()
              ? moment(String(data.paid_date), dateSourceFormat).format(
                  "MM-DD-YYYY"
                )
              : null,
            isPaid: data.is_paid,
            carrierPaymentType: data.carrier_payment_type,
            carrierPaymentAmount: data.carrier_payment_amount,
            receivedAt: moment(
              String(data.received_at),
              dateSourceFormat
            ).isValid()
              ? moment(String(data.received_at), dateSourceFormat).format(
                  "MM-DD-YYYY"
                )
              : null,
            invoiceAmount: data.load_margin.customer_total_price,
            createMethod: data.create_method,
            dispatcher_id: data.dispatcher,
            billingReferenceNumber: data.billing_reference_number,
          }
          commit("setShipmentDetail", shipDetail)

          const tenderDetail = {
            customerBase: data.customer_price || 0.0,
            loadMargin: data.load_margin.margin || 0.0,
            percentMargin: data.percent_margin || 0,
            carrierStartBuy: data.carrier_start_buy || 0.0,
            carrierMaxBuy: data.carrier_max_buy || 0.0,
            carrierLineHaul: data.carrier_price || 0.0,
            fuelSurcharge: data.fuel_surcharge || 0.0,
            carrierAccessorial: data.accessorial || 0.0,
            customerTotalPrice: data.load_margin.customer_total_price,
            carrierTotalPrice: data.load_margin.carrier_total_price,
            carrierTotalAccessorials:
              data.load_margin.carrier_total_accessorials,
            customerTotalAccessorials:
              data.load_margin.customer_total_accessorials,
            carrierTotalPrepaidAccessorials:
              data.load_margin.carrier_total_prepaid_accessorial,
          }
          commit("setTenderDetails", tenderDetail)

          // Below code will be in loop
          if (data.shipper) {
            const shipperInfo = {
              ...common.methods.toCamelCase(data.shipper),
              name: data.shipper.name || "Add Shipper",
            }
            commit("setShipperInfo", shipperInfo)
          }

          if (data.consignee) {
            // Below code will be in loop
            const consignee = common.methods.toCamelCase(data.consignee)
            const consigneeInfo = {
              ...consignee,
              name: data.consignee.name || "Add Consignee",
              zipcode: consignee.postalCode,
            }
            commit("setConsigneeInfo", consigneeInfo)
          }

          if (data.carrier) {
            const carrier = common.methods.toCamelCase(data.carrier)
            const carrierInfo = {
              ...carrier,
              state: carrier.stateProvinceRegion,
            }
            commit("setCarrierInfo", carrierInfo)
          }

          const loadDate = data.load_dates
          const timezone = momentTimezone.tz.guess()
          const loadDates = {
            dateCreated: moment(
              String(loadDate.date_created),
              dateSourceFormat
            ).isValid()
              ? momentTimezone(loadDate.date_created)
                  .tz(timezone)
                  .format("MM-DD-YYYY HH:mm z")
              : null,
            bookedDate: moment(
              String(loadDate.booked_date),
              dateSourceFormat
            ).isValid()
              ? momentTimezone(loadDate.booked_date)
                  .tz(timezone)
                  .format("MM-DD-YYYY HH:mm z")
              : null,
            confirmedDate: moment(
              String(loadDate.confirmed_date),
              dateSourceFormat
            ).isValid()
              ? momentTimezone(loadDate.confirmed_date)
                  .tz(timezone)
                  .format("MM-DD-YYYY HH:mm z")
              : null,
            pickedDate: moment(
              String(loadDate.picked_date),
              dateSourceFormat
            ).isValid()
              ? momentTimezone(loadDate.picked_date)
                  .tz(timezone)
                  .format("MM-DD-YYYY HH:mm z")
              : null,
            deliveredDate: moment(
              String(loadDate.delivered_date),
              dateSourceFormat
            ).isValid()
              ? momentTimezone(loadDate.delivered_date)
                  .tz(timezone)
                  .format("MM-DD-YYYY HH:mm z")
              : null,
            invoicedDate: moment(
              String(loadDate.invoiced_date),
              dateSourceFormat
            ).isValid()
              ? momentTimezone(loadDate.invoiced_date)
                  .tz(timezone)
                  .format("MM-DD-YYYY HH:mm z")
              : null,
            paidDate: moment(
              String(loadDate.paid_date),
              dateSourceFormat
            ).isValid()
              ? momentTimezone(loadDate.paid_date)
                  .tz(timezone)
                  .format("MM-DD-YYYY HH:mm z")
              : null,
            assignedAt: moment(
              String(loadDate.assigned_at),
              dateSourceFormat
            ).isValid()
              ? momentTimezone(loadDate.assigned_at)
                  .tz(timezone)
                  .format("MM-DD-YYYY HH:mm z")
              : null,
            updatedAt: moment(
              String(loadDate.updated_at),
              dateSourceFormat
            ).isValid()
              ? momentTimezone(loadDate.updated_at)
                  .tz(timezone)
                  .format("MM-DD-YYYY HH:mm z")
              : null,
            archivedAt: moment(
              String(loadDate.archived_date),
              dateSourceFormat
            ).isValid()
              ? momentTimezone(loadDate.archived_date)
                  .tz(timezone)
                  .format("MM-DD-YYYY HH:mm z")
              : null,
            dueDateEarly: moment(
              String(loadDate.due_date_early),
              dateSourceFormat
            ).isValid()
              ? momentTimezone(loadDate.due_date_early).format("MM-DD-YYYY")
              : null,
            dueDateLate: moment(
              String(loadDate.due_date_late),
              dateSourceFormat
            ).isValid()
              ? momentTimezone(loadDate.due_date_late).format("MM-DD-YYYY")
              : null,
          }
          commit("setLoadDates", loadDates)

          if (data.commodity_dimensions) {
            const commodityInfo = {
              ...common.methods.toCamelCase(data.commodity_dimensions),
              temperature: data.temperature,
              declaredValue: data.declared_value,
            }
            commit("setCommodity", commodityInfo)
          }

          const loadInfo = {
            shipperEarlyTime: data.pickup_early_time,
            consigneeEarlyTime: data.delivery_early_time,
            shipperPickupNumber: data.shipper_pickup_number,
            deliveryNumber: data.consignee_delivery_number,
            shipperLateTime: data.pickup_late_time,
            consigneeLateTime: data.delivery_late_time,
            shipperNotes: data.shipper_pickup_notes,
            consigneeNotes: data.consignee_delivery_notes,
            loadboardPostStatuses: common.methods.toCamelCase(data?.loadboard_post_statuses ?? []),
          }
          commit("setLoadInfo", loadInfo)
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve load detail.",
            type: snackbarType.error,
          }
          commit("setLoadingIcon", false)
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  SET_LOAD_ID: ({ commit }, payload) => {
    commit("setLoadId", payload)
  },
  GET_LOAD_ACCESSORIALS: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/loads/api/load-accessorial/${payload}/`
        )
        .then((response) => {
          const customerAccessorialList = []
          const carrierAccessorialList = []

          response.data.results.forEach((acc) => {
            const accessorial = {
              accessorialType: acc.accessorial_type,
              accessorialTypeText: acc.accessorial_type_text,
              amount: acc.amount,
              chargeTo: acc.charge_to,
              chargeToDisplay: acc.charge_to_display,
              chargeType: acc.charge_type,
              chargeTypeDisplay: acc.charge_type_display,
              id: acc.id,
              load: acc.load,
              quantity: acc.quantity,
              reason: acc.reason,
              prepaid: acc.prepaid,
              totalAmount: acc.total_amount,
            }
            if (acc.charge_to === 1) customerAccessorialList.push(accessorial)
            else carrierAccessorialList.push(accessorial)
          })

          commit("setLoadCustomerAccessorialList", customerAccessorialList)
          commit("setLoadCarrierAccessorialList", carrierAccessorialList)
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve load accessorials.",
            type: snackbarType.error,
          }
          commit("setLoadingIcon", false)
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  DELETE_LOAD_ACCESSORIAL: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingIcon", true)
      api
        .delete(
          `${process.env.VUE_APP_BASE_URL}/loads/api/load-accessorial-rud/${payload}/`
        )
        .then(() => {
          const snackbar = {
            status: true,
            text: "Load accessorial deleted successfully.",
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("setLoadingIcon", false)
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to delete load accessorial.",
            type: snackbarType.error,
          }
          commit("setLoadingIcon", false)
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e.response.data)
        })
    })
  },
  ADD_LOAD_ACCESSORIAL: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingIcon", true)
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/loads/api/load-accessorial/${payload.loadId}/`,
          {
            ...payload.details,
          }
        )
        .then(() => {
          const snackbar = {
            status: true,
            text: "Load accessorial created successfully.",
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("setLoadingIcon", false)
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: e.response.data,
            type: snackbarType.error,
          }
          commit("setLoadingIcon", false)
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e.response.data)
        })
    })
  },
  UPDATE_LOAD_ACCESSORIAL: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingIcon", true)
      api
        .put(
          `${process.env.VUE_APP_BASE_URL}/loads/api/load-accessorial-rud/${payload.id}/`,
          {
            ...payload.details,
          }
        )
        .then(() => {
          const snackbar = {
            status: true,
            text: "Load accessorial updated successfully.",
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("setLoadingIcon", false)
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to update load accessorial.",
            type: snackbarType.error,
          }
          commit("setLoadingIcon", false)
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e.response.data)
        })
    })
  },
  GET_LOAD_STATUS_CHOICES: ({ commit, state }) => {
    const loadStatusChoices = common.methods.getWithExpiry(
      "load_status_choices"
    )
    if (loadStatusChoices) {
      commit("setLoadStatusChoices", loadStatusChoices)
      const status = state.shipmentDetail.loadStatus
      const index = state.loadStatusChoices.findIndex((x) => x.name === status)
      commit("setCurrentLoadStatus", index)
    } else {
      api
        .get(`${process.env.VUE_APP_BASE_URL}/loads/api/load-status-choices/`)
        .then((response) => {
          commit("setLoadStatusChoices", response.data)
          common.methods.setWithExpiry(
            "load_status_choices",
            response.data,
            process.env.VUE_APP_LOCALSTORAGE_TIME
          )
          const status = state.shipmentDetail.loadStatus
          const index = state.loadStatusChoices.findIndex(
            (x) => x.name === status
          )
          commit("setCurrentLoadStatus", index)
        })
        .catch(() => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve load status choices.",
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
        })
    }
  },
  GET_SMS_MESSAGES: ({ commit }, payload) => {
    commit("setLoadingIcon", true)
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/sms/api/list-sms-messages/${payload}/`
      )
      .then((response) => {
        const _data = response.data.results
        if (_data.length > 0) {
          const messageList = []
          for (let i = 0; i < _data.length; i++) {
            const message = _data[i]
            const sms = {
              userFullName: message.user_full_name || "None",
              createdAt: message.created_at || "None",
              createdBy: message.created_by || "None",
              loadId: message.load,
              template: message.template,
              message: message.message,
            }
            messageList.push(sms)
          }
          commit("setSmsMessages", messageList)
        }
        commit("setLoadingIcon", false)
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve SMS messages.",
          type: snackbarType.error,
        }
        commit("setLoadingIcon", false)
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_SMS_TEMPLATE: ({ commit }) => {
    return new Promise((resolve, reject) => {
      api
        .get(`${process.env.VUE_APP_BASE_URL}/sms/api/sms-message-templates/`)
        .then((response) => {
          const _data = response.data.results
          if (_data.length > 0) {
            const templateList = []
            for (let i = 0; i < _data.length; i++) {
              const data = _data[i]
              const template = {
                id: data.id,
                name: data.name || "None",
                createdAt: data.created_at || "None",
                description: data.description || "None",
                messageLong: data.message_long,
                messageShort: data.message_short,
              }
              templateList.push(template)
            }
            commit("setSmsTemplate", templateList)
            resolve()
          }
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve SMS templates.",
            type: snackbarType.error,
          }
          commit("setLoadingIcon", false)
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  GET_LOAD_FILES: ({ commit }, payload) => {
    commit("setLoadingIcon", true)
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/loads/api/list-load-upload/${payload}/`
      )
      .then((response) => {
        var _data = response.data.results
        if (_data.length > 0) {
          var fileList = []
          for (let i = 0; i < _data.length; i++) {
            const data = _data[i]
            var fileData = {
              userFullName: data.user_full_name || "None",
              timeStamp: data.time_stamp || "None",
              id: data.id,
              loadId: data.load,
              uploadedBy: data.uploaded_by,
              fileURL: data.file,
              fileName: data.file_name,
              documentTypeDisplay: data.document_type_display,
              documentType: data.document_type,
            }
            fileList.push(fileData)
          }
          commit("setFileList", fileList)
        }
        commit("setLoadingIcon", false)
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve files.",
          type: snackbarType.error,
        }
        commit("setLoadingIcon", false)
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_NOTES: ({ commit }, payload) => {
    commit("setLoadingIcon", true)
    const params = {
      limit: payload.limit,
      offset: payload.offset,
    }
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/loads/api/create-list-load-comment/${payload.loadId}/`,
        {
          params,
        }
      )
      .then((response) => {
        const _data = response.data.results
        const noteData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        for (let i = 0; i < _data.length; i++) {
          const data = _data[i]
          const note = {
            id: data.id,
            author: data.author || "None",
            text: data.text,
            timeStamp: data.time_stamp,
            userFullName: data.user_full_name,
            loadId: data.load,
          }
          noteData.results.push(note)
        }
        commit("setNoteList", noteData)
        commit("setLoadingIcon", false)
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve notes.",
          type: snackbarType.error,
        }
        commit("setLoadingIcon", false)
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_AUDIT_LOG: ({ state, commit }, payload) => {
    if (payload.offset === 0) {
      commit("setAuditLog", auditLogDefaultState)
    }
    commit("setLoadingIcon", true)
    const params = {
      limit: payload.limit,
      offset: payload.offset,
    }
    if (payload.fieldName) {
      params.field_name = payload.fieldName
    }
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/loads/api/history/${payload.loadId}/`,
        {
          params,
        }
      )
      .then((response) => {
        const auditData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: state.auditLog.results.concat(
            response.data.results
              .filter((item) => item.changes && item.changes.length) // filtering out items that have empty changes field
              .map((item) => ({
                fullName: item.by ? item.by.full_name : "System",
                username: item.by ? item.by.username : "System",
                timeStamp: item.at,
                changeType: item.change_type,
                changes: item.changes,
              }))
          ),
        }
        commit("setAuditLog", auditData)
        commit("setLoadingIcon", false)
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve audit log.",
          type: snackbarType.error,
        }
        commit("setLoadingIcon", false)
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  SEND_SMS: ({ commit, dispatch }, payload) => {
    commit("setLoadingIcon", true)
    api
      .post(
        `${process.env.VUE_APP_BASE_URL}/sms/api/send-sms-message/${payload.loadId}/`,
        {
          template: payload.template,
        }
      )
      .then(() => {
        const snackbar = {
          status: true,
          text: "SMS has been sent.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_SMS_MESSAGES", payload.loadId)
        commit("setLoadingIcon", false)
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "SMS Failed to send",
          type: snackbarType.error,
        }
        commit("setLoadingIcon", false)
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  SUBMIT_NOTE: ({ commit, dispatch }, payload) => {
    commit("setLoadingIcon", true)
    api
      .post(
        `${process.env.VUE_APP_BASE_URL}/loads/api/create-list-load-comment/${payload.loadId}/`,
        {
          text: payload.text,
        }
      )
      .then(() => {
        const snackbar = {
          status: true,
          text: "Noted added successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        const params = {
          loadId: payload.loadId,
          limit: 10,
          offset: 0,
        }
        dispatch("GET_NOTES", params)
        commit("setLoadingIcon", false)
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to add note.",
          type: snackbarType.error,
        }
        commit("setLoadingIcon", false)
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  UPDATE_NOTE: ({ commit, dispatch }, payload) => {
    commit("setLoadingIcon", true)
    api
      .put(
        `${process.env.VUE_APP_BASE_URL}/loads/api/rud-load-comment/${payload.id}/`,
        {
          text: payload.text,
        }
      )
      .then(() => {
        const snackbar = {
          status: true,
          text: "Noted updated successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        const params = {
          id: payload.loadId,
          limit: 10,
          offset: 0,
        }
        dispatch("GET_NOTES", params)
        commit("setLoadingIcon", false)
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to update note.",
          type: snackbarType.error,
        }
        commit("setLoadingIcon", false)
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  UPLOAD_FILE: ({ commit, dispatch }, payload) => {
    api
      .post(
        `${process.env.VUE_APP_BASE_URL}/loads/api/list-load-upload/${payload.load}/`,
        payload.fmData,
        {
          onUploadProgress: function (progressEvent) {
            commit(
              "setUploadProgress",
              parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              )
            )
          },
        }
      )
      .then(() => {
        const snackbar = {
          status: true,
          text: "File uploaded successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_LOAD_FILES", payload.load)
        commit("setUploadProgress", 0)
        commit("fileInput", null)
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to upload file.",
          type: snackbarType.error,
        }
        commit("setUploadProgress", 0)
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  DELETE_FILE: ({ commit, dispatch }, payload) => {
    commit("setLoadingIcon", true)
    api
      .delete(
        `${process.env.VUE_APP_BASE_URL}/loads/api/rud-load-upload/${payload.fileId}/`
      )
      .then(() => {
        const snackbar = {
          status: true,
          text: "File deleted successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_LOAD_FILES", payload.loadId)
        commit("setLoadingIcon", false)
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to delete file.",
          type: snackbarType.error,
        }
        commit("setLoadingIcon", false)
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  DELETE_NOTE: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingIcon", true)
      api
        .delete(
          `${process.env.VUE_APP_BASE_URL}/loads/api/rud-load-comment/${payload.noteId}/`
        )
        .then(() => {
          const snackbar = {
            status: true,
            text: "Note deleted successfully.",
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("setLoadingIcon", false)
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to delete note.",
            type: snackbarType.error,
          }
          commit("setLoadingIcon", false)
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e.response.data)
        })
    })
  },
  GET_ALL_LOADS: ({ commit }, payload) => {
    commit("setLoadingIcon", true)
    api
      .get(`${process.env.VUE_APP_BASE_URL}/loads/api/all-loads-list/`, {
        params: payload,
      })
      .then((response) => {
        const loadData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const loadDataRecord = data[i]
          const load = {
            loadNumber: loadDataRecord.id,
            loadStatus: loadDataRecord.load_status,
            customer: loadDataRecord.customer_company,
            csr: loadDataRecord.carrier_sales_rep,
            carrier: loadDataRecord.carrier,
            cost: {
              min: loadDataRecord.carrier_start_buy,
              max: loadDataRecord.carrier_max_buy,
            },
            pickupDate: loadDataRecord.pickup_date,
            deliveryDate: loadDataRecord.delivery_date,
            shipper: loadDataRecord.shipper,
            consignee: loadDataRecord.consignee,
            equipment: loadDataRecord.equipment_type,
            poNumber: loadDataRecord.po_number,
            puNumber: loadDataRecord.pu_number,
            posted: loadDataRecord.loadboard,
            deliveryEarlyTime: loadDataRecord.delivery_early_time,
            deliveryLateTime: loadDataRecord.delivery_late_time,
            pickupEarlyTime: loadDataRecord.pickup_early_time,
            pickupLateTime: loadDataRecord.pickup_late_time,
            customerReferenceId: loadDataRecord.customer_reference_id,
            archived: loadDataRecord.archived,
            archivedAt: loadDataRecord.archived_at,
            selectedClass: null,
            agentName: loadDataRecord.agent_name,
            temperature: loadDataRecord.temperature,
            declaredValue: loadDataRecord.declared_value,
            loadboardPostStatuses: common.methods.toCamelCase(loadDataRecord.loadboard_post_statuses)
          }
          loadData.results.push(load)
        }
        commit("setLoadList", loadData)
        commit("setLoadingIcon", false)
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve loads.",
          type: snackbarType.error,
        }
        commit("setLoadingIcon", false)
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  CREATE_NEW_LOAD: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingIcon", true)
      api
        .post(`${process.env.VUE_APP_BASE_URL}/loads/api/load-create/`, payload)
        .then((response) => {
          commit("setLoadId", response.data.id)
          const snackbar = {
            status: true,
            text: `Load ${response.data.id} created successfully.`,
            type: snackbarType.success,
          }
          resolve(commit("main/SET_SNACKBAR", snackbar, { root: true }))
          commit("setLoadingIcon", false)
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: e.response.data,
            type: snackbarType.error,
          }
          commit("setLoadingIcon", false)
          reject(commit("main/SET_SNACKBAR", snackbar, { root: true }))
        })
    })
  },
  UPDATE_LOADBOARD_STATUS: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingIcon", true)
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/integrations/loadboards/${payload.loadId}/`,
          {
            loadboard: payload.posted,
          }
        )
        .then((response) => {
          const snackbar = {
            status: true,
            text: response.data,
            type: snackbarType.success,
          }
          resolve(
            commit("main/SET_SNACKBAR", snackbar, { root: true }),
            commit("setLoadingIcon", false)
          )
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: e.response.data,
            type: snackbarType.error,
          }
          commit("setLoadingIcon", false)
          reject(commit("main/SET_SNACKBAR", snackbar, { root: true }))
        })
    })
  },
  FETCH_TRUCKSTOP_PRICE: ({ commit, dispatch }, payload) => {
    commit("setLoadingIcon", true)
    api
      .post(
        `${process.env.VUE_APP_BASE_URL}/integrations/truckstop-rate-request/${payload.loadId}/`,
        {
          margin: payload.margin,
          radius: payload.radius,
        }
      )
      .then((response) => {
        if (response.data) {
          const data = response.data
          const calculatedTruckstopRates =
            response.data.calculated_truckstop_rates
          const rate = {
            id: data.id,
            load: data.load,
            agentCompany: data.agent_company,
            margin: data.margin,
            radius: data.radius,
            paid30dReports: data.paid_30d_reports,
            paid90dReports: data.paid_90d_reports,
            paid365dReports: data.paid_365d_reports,
            posted7dReports: data.posted_7d_reports,
            posted15dReports: data.posted_15d_reports,
            posted30dReports: data.posted_30d_reports,
            posted90dReports: data.posted_90d_reports,
            posted365dReports: data.posted_365d_reports,
            paid30dTrucker: data.paid_30d_trucker,
            paid30dShipper: data.paid_30d_shipper,
            paid90dTrucker: data.paid_90d_trucker,
            paid90dShipper: data.paid_90d_shipper,
            paid365dTrucker: data.paid_365d_trucker,
            paid365dShipper: data.paid_365d_shipper,
            posted7dTrucker: data.posted_7d_trucker,
            posted7dShipper: data.posted_7d_shipper,
            posted15dTrucker: data.posted_15d_trucker,
            posted15dShipper: data.posted_15d_shipper,
            posted30dTrucker: data.posted_30d_trucker,
            posted30dShipper: data.posted_30d_shipper,
            posted90dTrucker: data.posted_90d_trucker,
            posted90dShipper: data.posted_90d_shipper,
            posted365dTrucker: data.posted_365d_trucker,
            posted365dShipper: data.posted_365d_shipper,
            paid30dShipperRate: calculatedTruckstopRates.paid_30d_shipper,
            paid90dShipperRate: calculatedTruckstopRates.paid_90d_shipper,
            paid365dShipperRate: calculatedTruckstopRates.paid_365d_shipper,
            paid30dTruckerRate: calculatedTruckstopRates.paid_30d_trucker,
            paid90dTruckerRate: calculatedTruckstopRates.paid_90d_trucker,
            paid365dTruckerRate: calculatedTruckstopRates.paid_365d_trucker,
            posted7dShipperRate: calculatedTruckstopRates.posted_7d_shipper,
            posted15dShipperRate: calculatedTruckstopRates.posted_15d_shipper,
            posted30dShipperRate: calculatedTruckstopRates.posted_30d_shipper,
            posted90dShipperRate: calculatedTruckstopRates.posted_90d_shipper,
            posted365dShipperRate: calculatedTruckstopRates.posted_365d_shipper,
            posted7dTruckerRate: calculatedTruckstopRates.posted_7d_trucker,
            posted15dTruckerRate: calculatedTruckstopRates.posted_15d_trucker,
            posted30dTruckerRate: calculatedTruckstopRates.posted_30d_trucker,
            posted90dTruckerRate: calculatedTruckstopRates.posted_90d_trucker,
            posted365dTruckerRate: calculatedTruckstopRates.posted_365d_trucker,
          }
          commit("setTruckStopPrices", rate)
        }
        commit("setLoadingIcon", false)
      })
      .catch((e) => {
        if (e.response.status === 422) {
          const snackbar = {
            status: true,
            text: e.response.data,
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
        } else {
          const snackbar = {
            status: true,
            text: "Failed to retrieve rates.",
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
        }
        commit("setLoadingIcon", false)
      })
  },
  GET_TRUCKSTOP_PRICE: ({ commit, dispatch }, payload) => {
    commit("setLoadingIcon", true)
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/integrations/truckstop-rate-request/${payload}/`
      )
      .then((response) => {
        if (response.data.results.length) {
          const data = response.data.results[0]
          const calculatedTruckstopRates =
            response.data.results[0].calculated_truckstop_rates
          const rate = {
            id: data.id,
            load: data.load,
            agentCompany: data.agent_company,
            margin: data.margin,
            radius: data.radius,
            paid30dReports: data.paid_30d_reports,
            paid90dReports: data.paid_90d_reports,
            paid365dReports: data.paid_365d_reports,
            posted7dReports: data.posted_7d_reports,
            posted15dReports: data.posted_15d_reports,
            posted30dReports: data.posted_30d_reports,
            posted90dReports: data.posted_90d_reports,
            posted365dReports: data.posted_365d_reports,
            paid30dTrucker: data.paid_30d_trucker,
            paid30dShipper: data.paid_30d_shipper,
            paid90dTrucker: data.paid_90d_trucker,
            paid90dShipper: data.paid_90d_shipper,
            paid365dTrucker: data.paid_365d_trucker,
            paid365dShipper: data.paid_365d_shipper,
            posted7dTrucker: data.posted_7d_trucker,
            posted7dShipper: data.posted_7d_shipper,
            posted15dTrucker: data.posted_15d_trucker,
            posted15dShipper: data.posted_15d_shipper,
            posted30dTrucker: data.posted_30d_trucker,
            posted30dShipper: data.posted_30d_shipper,
            posted90dTrucker: data.posted_90d_trucker,
            posted90dShipper: data.posted_90d_shipper,
            posted365dTrucker: data.posted_365d_trucker,
            posted365dShipper: data.posted_365d_shipper,
            paid30dShipperRate: calculatedTruckstopRates
              ? calculatedTruckstopRates.paid_30d_shipper
              : "",
            paid90dShipperRate: calculatedTruckstopRates
              ? calculatedTruckstopRates.paid_90d_shipper
              : "",
            paid365dShipperRate: calculatedTruckstopRates
              ? calculatedTruckstopRates.paid_365d_shipper
              : "",
            paid30dTruckerRate: calculatedTruckstopRates
              ? calculatedTruckstopRates.paid_30d_trucker
              : "",
            paid90dTruckerRate: calculatedTruckstopRates
              ? calculatedTruckstopRates.paid_90d_trucker
              : "",
            paid365dTruckerRate: calculatedTruckstopRates
              ? calculatedTruckstopRates.paid_365d_trucker
              : "",
            posted7dShipperRate: calculatedTruckstopRates
              ? calculatedTruckstopRates.posted_7d_shipper
              : "",
            posted15dShipperRate: calculatedTruckstopRates
              ? calculatedTruckstopRates.posted_15d_shipper
              : "",
            posted30dShipperRate: calculatedTruckstopRates
              ? calculatedTruckstopRates.posted_30d_shipper
              : "",
            posted90dShipperRate: calculatedTruckstopRates
              ? calculatedTruckstopRates.posted_90d_shipper
              : "",
            posted365dShipperRate: calculatedTruckstopRates
              ? calculatedTruckstopRates.posted_365d_shipper
              : "",
            posted7dTruckerRate: calculatedTruckstopRates
              ? calculatedTruckstopRates.posted_7d_trucker
              : "",
            posted15dTruckerRate: calculatedTruckstopRates
              ? calculatedTruckstopRates.posted_15d_trucker
              : "",
            posted30dTruckerRate: calculatedTruckstopRates
              ? calculatedTruckstopRates.posted_30d_trucker
              : "",
            posted90dTruckerRate: calculatedTruckstopRates
              ? calculatedTruckstopRates.posted_90d_trucker
              : "",
            posted365dTruckerRate: calculatedTruckstopRates
              ? calculatedTruckstopRates.posted_365d_trucker
              : "",
          }
          commit("setTruckStopPrices", rate)
        }
        commit("setLoadingIcon", false)
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve rates.",
          type: snackbarType.error,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        commit("setLoadingIcon", false)
      })
  },
  POST_ARCHIVED: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingIcon", true)
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/loads/api/load-archive/${payload.loadId}/`,
          {
            archived: payload.archived,
          }
        )
        .then((response) => {
          const snackbar = {
            status: true,
            text: response.data,
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("setLoadingIcon", false)
          resolve(payload.archived)
        })
        .catch((error) => {
          const snackbar = {
            status: true,
            text: error.response.data.error,
            type: snackbarType.error,
          }
          commit("setLoadingIcon", false)
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(error)
        })
    })
  },
  GET_CURRENT_TRACKING_UPDATE: ({ commit, dispatch }, payload) => {
    return new Promise((resolve) => {
      commit("setLoadingIcon", true)
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/loads/api/load-status-tracking-update/${payload}/`,
          payload.loadId
        )
        .then((response) => {
          if (response.data) {
            const data = response.data
            const payload = {
              pickedAt: data.picked_at
                ? common.methods.formatDate(data.picked_at)
                : null,
              deliveredAt: data.delivered_at
                ? common.methods.formatDate(data.delivered_at)
                : null,
              pickupApptAt: data.pickup_appt_at
                ? common.methods.formatDate(data.pickup_appt_at)
                : null,
              deliveryApptAt: data.delivery_appt_at
                ? common.methods.formatDate(data.delivery_appt_at)
                : null,
              arrivedAtShipperAt: data.arrived_at_shipper_at
                ? common.methods.formatDate(data.arrived_at_shipper_at)
                : null,
              arrivedAtConsigneeAt: data.arrived_at_consignee_at
                ? common.methods.formatDate(data.arrived_at_consignee_at)
                : null,
              onTimePick: data.on_time_pick,
              onTimeDelivery: data.on_time_delivery,
            }
            commit("setTrackingUpdate", payload)
            commit("setLoadingIcon", false)
            resolve()
          } else {
            commit("setLoadingIcon", false)
            resolve()
          }
        })
        .catch(() => {
          const payload = {
            pickedAt: null,
            deliveredAt: null,
            pickupApptAt: null,
            deliveryApptAt: null,
            arrivedAtShipperAt: null,
            arrivedAtConsigneeAt: null,
            onTimePick: false,
            onTimeDelivery: false,
          }
          commit("setTrackingUpdate", payload)
          const snackbar = {
            status: true,
            text: "Failed to retrieve tracking update.",
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("setLoadingIcon", false)
        })
    })
  },
  POST_TRACKING_UPDATE: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingIcon", true)
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/loads/api/load-status-tracking-update/${payload.loadId}/`,
          payload.trackingPayload
        )
        .then(() => {
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Tracking update unsuccessful.",
            type: snackbarType.error,
          }
          commit("setLoadingIcon", false)
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  ADD_NEW_LOAD_STOP: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingIcon", true)
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/loads/api/add-stop/${payload.load}/`,
          payload
        )
        .then(() => {
          const snackbar = {
            status: true,
            text: "Load stop added successfully.",
            type: snackbarType.success,
          }
          resolve(commit("main/SET_SNACKBAR", snackbar, { root: true }))
          commit("setLoadingIcon", false)
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: e.response.data,
            type: snackbarType.error,
          }
          commit("setLoadingIcon", false)
          reject(commit("main/SET_SNACKBAR", snackbar, { root: true }))
        })
    })
  },
  DELETE_LOAD_STOP: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingIcon", true)
      api
        .delete(
          `${process.env.VUE_APP_BASE_URL}/loads/api/load-stop-rud/${payload}/`
        )
        .then(() => {
          const snackbar = {
            status: true,
            text: "Load stop deleted successfully.",
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("setLoadingIcon", false)
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to delete load stop.",
            type: snackbarType.error,
          }
          commit("setLoadingIcon", false)
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e.response.data)
        })
    })
  },
  GET_LOAD_STOP_DETAIL: ({ commit, dispatch }, payload) => {
    commit("setLoadingIcon", true)
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/loads/api/load-stop-rud/${payload}/`
      )
      .then((response) => {
        const data = response.data
        const loadStop = {
          id: data.id,
          loadId: data.load,
          locationId: data.location,
          locationName: data.location_name,
          stopDate: data.stop_date,
          stopEarlyTime: data.stop_early_time,
          stopLateTime: data.stop_late_time,
          phone: data.phone,
          contactName: data.contact_name,
          notes: data.notes,
          stopType: data.stop_type,
          order: data.order,
        }
        commit("setLoadStopDetail", loadStop)
        commit("setLoadingIcon", false)
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve load stop detail.",
          type: snackbarType.error,
        }
        commit("setLoadingIcon", false)
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  LOAD_STOP_ORDER_CHANGE: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingIcon", true)
      api
        .put(
          `${process.env.VUE_APP_BASE_URL}/loads/api/load-stop-order/${payload.id}/`,
          {
            direction: payload.direction,
          }
        )
        .then(() => {
          const snackbar = {
            status: true,
            text: "Load stop order change successfully.",
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("setLoadingIcon", false)
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: e.response.data,
            type: snackbarType.error,
          }
          commit("setLoadingIcon", false)
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  UPDATE_LOAD_STOP: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingIcon", true)
      api
        .put(
          `${process.env.VUE_APP_BASE_URL}/loads/api/load-stop-rud/${payload.id}/`,
          {
            load: payload.load,
            location: payload.location,
            stop_date: payload.stop_date,
            stop_early_time: payload.stop_early_time,
            stop_late_time: payload.stop_late_time,
            phone: payload.phone,
            contact_name: payload.contact_name,
            notes: payload.notes,
            stop_type: payload.stop_type,
          }
        )
        .then(() => {
          const snackbar = {
            status: true,
            text: "Load stop updated successfully.",
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("setLoadingIcon", false)
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: e.response.data,
            type: snackbarType.error,
          }
          commit("setLoadingIcon", false)
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  POST_HOLD: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingIcon", true)
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/loads/api/load-hold/${payload.loadId}/`,
          {
            hold: payload.hold,
          }
        )
        .then((response) => {
          const snackbar = {
            status: true,
            text: response.data,
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("setLoadingIcon", false)
          resolve(payload.hold)
        })
        .catch((error) => {
          const snackbar = {
            status: true,
            text: error.response.data.error,
            type: snackbarType.error,
          }
          commit("setLoadingIcon", false)
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(error)
        })
    })
  },
}

const mutations = {
  setCommodityAndDimensionsInfoEditing(state, payload) {
    state.commodityAndDimensionsInfoEdit = payload
  },
  setLoadId(state, payload) {
    state.loadId = payload
  },
  setLoadCustomerAccessorialList(state, payload) {
    state.loadCustomerAccessorialList = payload
  },
  setLoadCarrierAccessorialList(state, payload) {
    state.loadCarrierAccessorialList = payload
  },
  setAutoPriceEnabled(state, payload) {
    state.autoPriceEnabled = payload
  },
  setShipmentDetail(state, payload) {
    state.shipmentDetail = payload
  },
  setShipmentDetailEditing(state, payload) {
    state.shipmentDetailEditing = payload
  },
  setShipperInfo(state, payload) {
    state.shipperInfo = payload
  },
  setShipperDetailEditing(state, payload) {
    state.shipperDetailEditing = payload
  },
  setConsigneeInfo(state, payload) {
    state.consigneeInfo = payload
  },
  setLoadInfo(state, payload) {
    state.loadInfo = payload
  },
  setConsigneeDetailEditing(state, payload) {
    state.consigneeDetailEditing = payload
  },
  setCarrierInfo(state, payload) {
    state.carrierInfo = payload
  },
  setCarrierInfoEditing(state, payload) {
    state.carrierInfoEditing = payload
  },
  setLoadDates(state, payload) {
    state.loadDates = payload
  },
  setCommodity(state, payload) {
    state.commodityInfo = payload
  },
  setShipperId(state, payload) {
    state.shipperId = payload
  },
  setConsigneeId(state, payload) {
    state.consigneeId = payload
  },
  setSmsMessages(state, payload) {
    state.smsMessages = payload
  },
  setSmsTemplate(state, payload) {
    state.smsTemplate = payload
  },
  setFileList(state, payload) {
    state.fileList = payload
  },
  setNoteList(state, payload) {
    state.noteList = payload
  },
  setAuditLog(state, payload) {
    state.auditLog = payload
  },
  setCustomerCompany(state, payload) {
    state.shipmentDetail.customer = payload
  },
  setAgent(state, payload) {
    state.shipmentDetail.agent = payload
  },
  setCsr(state, payload) {
    state.shipmentDetail.carrierSalesRep = payload
  },
  setOrderType(state, payload) {
    state.shipmentDetail.orderType = parseInt(payload.id)
  },
  setEquipmentType(state, payload) {
    state.shipmentDetail.equipmentType = payload
  },
  setTenderDetails(state, payload) {
    state.tenderDetails = payload
  },
  setTenderDetailEditing(state, payload) {
    state.tenderDetailEditing = payload
  },
  setUploadProgress(state, payload) {
    state.uploadProgress = payload
  },
  fileInput(state, payload) {
    state.fileInput = payload
  },
  setLoadList(state, payload) {
    state.loadList = payload
  },
  setLoadStatusChoices(state, payload) {
    state.loadStatusChoices = payload
  },
  setCurrentLoadStatus(state, payload) {
    state.currentLoadStatus = payload
  },
  setLoadingIcon(state, payload) {
    state.loadingIcon = payload
  },
  setCarrier(state, payload) {
    state.shipmentDetail.carrier.id = parseInt(payload.id)
  },
  setTruckStopPrices(state, payload) {
    state.truckStopPrices = payload
  },
  toggleLoadTrackingDialog(state, payload) {
    state.isLoadTrackingDialogOpen = payload
  },
  setTrackingUpdate: (state, payload) => {
    state.trackingUpdate = payload
  },
  setLoadTrackingDialogId(state, payload) {
    state.loadTrackingDialogId = payload
  },
  setLoadStatusUpdate(state, payload) {
    state.loadStatusUpdate = payload
  },
  setReloadMap(state, payload) {
    state.reloadMap = payload
  },
  setAddStopDialog(state, payload) {
    state.addStopDialog = payload
  },
  setLoadStopDetail(state, payload) {
    state.loadStopDetail = payload
  },
  setLoadStopId(state, payload) {
    state.loadStopId = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    loadboards
  }
}
