import api from "@/api/api"
import moment from "moment"
import common from "@/utils/common"
const snackbarType = common.data.snackbarType

const state = () => ({
  carrierMatchListCard: false,
  matchingShipperId: null,
  matchingConsigneeId: null,
  carrierMatches: {
    count: 0,
    next: null,
    previous: null,
    carrierMatchList: [],
  },
  outboundLoads: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  matchDialog: false,
  dialogLoad: null,
  backhualLoads: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  matchingLoads: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  quoteList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  quoteListByLoad: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  quoteListByCarrier: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
})

const getters = {
  matchingShipperId: (state) => {
    return state.matchingShipperId
  },
  matchingConsigneeId: (state) => {
    return state.matchingConsigneeId
  },
  carrierMatches: (state) => {
    return state.carrierMatches
  },
  carrierMatchListCard: (state) => {
    return state.carrierMatchListCard
  },
  outboundLoads: (state) => {
    return state.outboundLoads
  },
  matchDialog: (state) => {
    return state.matchDialog
  },
  dialogLoad: (state) => {
    return state.dialogLoad
  },
  backhualLoads: (state) => {
    return state.backhualLoads
  },
  matchingLoads: (state) => {
    return state.matchingLoads
  },
  quoteList: (state) => {
    return state.quoteList
  },
  quoteListByLoad: (state) => {
    return state.quoteListByLoad
  },
  quoteListByCarrier: (state) => {
    return state.quoteListByCarrier
  },
}

const actions = {
  CARRIER_MATCH_LIST_CARD({ commit, state }, payload) {
    commit("setCarrierMatchListCard", payload)
  },
  GET_CARRIER_MATCH_LIST({ commit, state }, payload) {
    commit("generic/setLoadingIcon", true, { root: true })
    return new Promise((resolve, reject) => {
      const queryParams = {
        shipper: payload.shipper,
        consignee: payload.consignee,
        equipment_type: payload.equipmentType,
        offset: payload.offset,
      }
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/carrier-matching/api/carrier-lane-matches/`,
          {
            params: queryParams,
          }
        )
        .then((response) => {
          const matchData = {
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            carrierMatchList: [],
          }
          const data = response.data.results
          if (data) {
            for (let i = 0; i < data.length; i++) {
              const carrierDataRecord = data[i]
              const carrier = {
                id: carrierDataRecord.carrier.id,
                name: carrierDataRecord.carrier.name,
                mcNumber: carrierDataRecord.carrier.mc_number,
                dotNumber: carrierDataRecord.carrier.dot_number,
                phone: carrierDataRecord.carrier.phone,
                phone_two: carrierDataRecord.carrier.phone_two,
                phone_three: carrierDataRecord.carrier.phone_three,
                email: carrierDataRecord.carrier.email,
                city: carrierDataRecord.carrier.city,
                state: carrierDataRecord.carrier.state,
                loadCount: carrierDataRecord.load_count,
                matchType: carrierDataRecord.match_type,
                averageRate: carrierDataRecord.average_rate,
                carrierBidAverage: carrierDataRecord.carrier_bid_average,
                averageRpm: carrierDataRecord.average_rpm,
              }
              matchData.carrierMatchList.push(carrier)
            }
          }
          commit("setCarrierMatches", matchData)
          commit("generic/setLoadingIcon", false, { root: true })
        })
        .catch(() => {
          commit("generic/setLoadingIcon", false, { root: true })
        })
    })
  },
  GET_OUTBOUND_LOADS({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      const queryParams = {
        limit: payload.limit,
        offset: payload.offset,
      }
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/carrier-matching/internal/outbound/${payload.carrierId}/`,
          {
            params: queryParams,
          }
        )
        .then((response) => {
          const loadData = {
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            results: [],
          }
          const data = response.data.results
          for (let i = 0; i < data.length; i++) {
            const loadObj = data[i].load
            const load = {
              carrierSalesRep: loadObj.carrier_sales_rep,
              agentName: loadObj.agent_name,
              id: loadObj.id,
              customerCompany: loadObj.customer_company,
              pickupDate: loadObj.pickup_date,
              deliveryDate: loadObj.delivery_date,
              customerReferenceId: loadObj.customer_reference_id,
              poNumber: loadObj.po_number,
              puNumber: loadObj.pu_number,
              shipper: loadObj.shipper,
              pickupEarlyTime: loadObj.pickup_early_time,
              pickupLateTime: loadObj.pickup_late_time,
              consignee: loadObj.consignee,
              deliveryEarlyTime: loadObj.delivery_early_time,
              deliveryLateTime: loadObj.delivery_late_time,
              carrierStartBuy: loadObj.carrier_start_buy,
              carrierMaxBuy: loadObj.carrier_max_buy,
              carrier: loadObj.carrier,
              equipmentType: loadObj.equipment_type,
              loadStatus: loadObj.load_status,
              loadboard: loadObj.loadboard,
              orderType: loadObj.order_type,
              orderTypeDisplay: loadObj.order_type_display,
              customerPrice: loadObj.customer_price,
              archived: loadObj.archived,
              loadWeight: loadObj.load_weight,
              loadHeight: loadObj.load_height,
              loadWidth: loadObj.load_width,
              loadLength: loadObj.load_length,
              miles: loadObj.miles,
              commodity: loadObj.commodity,
              loadMatchCount: data[i].load_count,
              distance: data[i].distance,
            }
            loadData.results.push(load)
          }
          commit("setOutboundLoads", loadData)
          commit("generic/setLoadingIcon", false, { root: true })
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve outbound loads.",
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  GET_BACKHUAL_LOADS({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const queryParams = {
        load_ids: payload.loadIds,
        limit: payload.limit,
        offset: payload.offset,
      }
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/carrier-matching/internal/backhaul/${payload.carrierId}/`,
          {
            params: queryParams,
          }
        )
        .then((response) => {
          const loadData = {
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            results: [],
          }
          const data = response.data.results
          for (let i = 0; i < data.length; i++) {
            const loadObj = data[i].load
            const load = {
              carrierSalesRep: loadObj.carrier_sales_rep,
              agentName: loadObj.agent_name,
              id: loadObj.id,
              customerCompany: loadObj.customer_company,
              pickupDate: loadObj.pickup_date,
              deliveryDate: loadObj.delivery_date,
              customerReferenceId: loadObj.customer_reference_id,
              poNumber: loadObj.po_number,
              puNumber: loadObj.pu_number,
              shipper: loadObj.shipper,
              pickupEarlyTime: loadObj.pickup_early_time,
              pickupLateTime: loadObj.pickup_late_time,
              consignee: loadObj.consignee,
              deliveryEarlyTime: loadObj.delivery_early_time,
              deliveryLateTime: loadObj.delivery_late_time,
              carrierStartBuy: loadObj.carrier_start_buy,
              carrierMaxBuy: loadObj.carrier_max_buy,
              carrier: loadObj.carrier,
              equipmentType: loadObj.equipment_type,
              loadStatus: loadObj.load_status,
              loadboard: loadObj.loadboard,
              orderType: loadObj.order_type,
              orderTypeDisplay: loadObj.order_type_display,
              customerPrice: loadObj.customer_price,
              archived: loadObj.archived,
              loadWeight: loadObj.load_weight,
              loadHeight: loadObj.load_height,
              loadWidth: loadObj.load_width,
              loadLength: loadObj.load_length,
              miles: loadObj.miles,
              commodity: loadObj.commodity,
              loadMatchCount: data[i].load_count,
              distance: data[i].distance,
            }
            loadData.results.push(load)
          }
          commit("setBackhualLoads", loadData)
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve backhual loads.",
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  GET_MATCHING_LOADS({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const queryParams = {
        load_ids: payload.loadIds,
        limit: payload.limit,
        offset: payload.offset,
      }
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/carrier-matching/internal/matching/${payload.carrierId}/`,
          {
            params: queryParams,
          }
        )
        .then((response) => {
          const loadData = {
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            results: [],
          }
          const data = response.data.results
          for (let i = 0; i < data.length; i++) {
            const loadObj = data[i].load
            const load = {
              carrierSalesRep: loadObj.carrier_sales_rep,
              agentName: loadObj.agent_name,
              id: loadObj.id,
              customerCompany: loadObj.customer_company,
              pickupDate: loadObj.pickup_date,
              deliveryDate: loadObj.delivery_date,
              customerReferenceId: loadObj.customer_reference_id,
              poNumber: loadObj.po_number,
              puNumber: loadObj.pu_number,
              shipper: loadObj.shipper,
              pickupEarlyTime: loadObj.pickup_early_time,
              pickupLateTime: loadObj.pickup_late_time,
              consignee: loadObj.consignee,
              deliveryEarlyTime: loadObj.delivery_early_time,
              deliveryLateTime: loadObj.delivery_late_time,
              carrierStartBuy: loadObj.carrier_start_buy,
              carrierMaxBuy: loadObj.carrier_max_buy,
              carrier: loadObj.carrier,
              equipmentType: loadObj.equipment_type,
              loadStatus: loadObj.load_status,
              loadboard: loadObj.loadboard,
              orderType: loadObj.order_type,
              orderTypeDisplay: loadObj.order_type_display,
              customerPrice: loadObj.customer_price,
              archived: loadObj.archived,
              loadWeight: loadObj.load_weight,
              loadHeight: loadObj.load_height,
              loadWidth: loadObj.load_width,
              loadLength: loadObj.load_length,
              miles: loadObj.miles,
              commodity: loadObj.commodity,
              loadMatchCount: data[i].load_count,
              distance: data[i].distance,
            }
            loadData.results.push(load)
          }
          commit("setMatchingLoads", loadData)
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve matching loads.",
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  GET_QUOTES({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/carrier-matching/api/list-quote/`,
          {
            params: payload,
          }
        )
        .then((response) => {
          const quoteData = {
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            results: [],
          }
          const data = response.data.results
          for (let i = 0; i < data.length; i++) {
            const quoteObj = data[i]
            const quote = {
              id: quoteObj.id,
              createdAt: moment(String(quoteObj.created_at)).isValid()
                ? moment(String(quoteObj.created_at)).format("MM-DD-YYYY HH:mm")
                : null,
              updatedAt: moment(String(quoteObj.updated_at)).isValid()
                ? moment(String(quoteObj.updated_at)).format("MM-DD-YYYY HH:mm")
                : null,
              archivedAt: quoteObj.archived_at,
              archived: quoteObj.archived,
              expiredAt: quoteObj.expired_at,
              expired: quoteObj.expired,
              carrierCompany: quoteObj.carrier_company,
              agentCompany: quoteObj.agent_company,
              load: quoteObj.load,
              shipper: quoteObj.load.shipper,
              consignee: quoteObj.load.consignee,
              carrierBid: quoteObj.carrier_bid,
              equipmentType: quoteObj.equipment_type,
              name: quoteObj.name,
              email: quoteObj.email,
              phone: quoteObj.phone,
              dotNumber: quoteObj.dot_number,
              mcNumber: quoteObj.mc_number,
            }
            quoteData.results.push(quote)
          }
          commit("setQuoteList", quoteData)
          commit("generic/setLoadingIcon", false, { root: true })
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve quotes.",
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  GET_QUOTES_BY_LOAD({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      const queryParams = {
        limit: payload.limit,
        offset: payload.offset,
      }
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/carrier-matching/api/create-quote/${payload.loadId}/`,
          {
            params: queryParams,
          }
        )
        .then((response) => {
          const quoteData = {
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            results: [],
          }
          const data = response.data.results
          for (let i = 0; i < data.length; i++) {
            const quoteObj = data[i]
            const quote = {
              id: quoteObj.id,
              createdAt: moment(String(quoteObj.created_at)).isValid()
                ? moment(String(quoteObj.created_at)).format("MM-DD-YYYY HH:mm")
                : null,
              updatedAt: moment(String(quoteObj.updated_at)).isValid()
                ? moment(String(quoteObj.updated_at)).format("MM-DD-YYYY HH:mm")
                : null,
              archivedAt: quoteObj.archived_at,
              archived: quoteObj.archived,
              expiredAt: quoteObj.expired_at,
              expired: quoteObj.expired,
              carrierCompany: quoteObj.carrier_company,
              agentCompany: quoteObj.agent_company,
              load: quoteObj.load,
              carrierBid: quoteObj.carrier_bid,
              equipmentType: quoteObj.equipment_type,
              name: quoteObj.name,
              email: quoteObj.email,
              phone: quoteObj.phone,
              dotNumber: quoteObj.dot_number,
              mcNumber: quoteObj.mc_number,
            }
            quoteData.results.push(quote)
          }
          commit("setQuoteListByLoad", quoteData)
          commit("generic/setLoadingIcon", false, { root: true })
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve quotes.",
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  GET_QUOTES_BY_CARRIER({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      const queryParams = {
        limit: payload.limit,
        offset: payload.offset,
      }
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/carrier-matching/api/carrier-quote-list/${payload.carrierId}/`,
          {
            params: queryParams,
          }
        )
        .then((response) => {
          const quoteData = {
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            results: [],
          }
          const data = response.data.results
          for (let i = 0; i < data.length; i++) {
            const quoteObj = data[i]
            const quote = {
              id: quoteObj.id,
              createdAt: moment(String(quoteObj.created_at)).isValid()
                ? moment(String(quoteObj.created_at)).format("MM-DD-YYYY HH:mm")
                : null,
              updatedAt: moment(String(quoteObj.updated_at)).isValid()
                ? moment(String(quoteObj.updated_at)).format("MM-DD-YYYY HH:mm")
                : null,
              archivedAt: quoteObj.archived_at,
              archived: quoteObj.archived,
              expiredAt: quoteObj.expired_at,
              expired: quoteObj.expired,
              carrierCompany: quoteObj.carrier_company,
              agentCompany: quoteObj.agent_company,
              load: quoteObj.load,
              shipper: quoteObj.load.shipper,
              consignee: quoteObj.load.consignee,
              carrierBid: quoteObj.carrier_bid,
              equipmentType: quoteObj.equipment_type,
              name: quoteObj.name,
              email: quoteObj.email,
              phone: quoteObj.phone,
              dotNumber: quoteObj.dot_number,
              mcNumber: quoteObj.mc_number,
            }
            quoteData.results.push(quote)
          }
          commit("setQuoteListByCarrier", quoteData)
          commit("generic/setLoadingIcon", false, { root: true })
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve quotes.",
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
}

const mutations = {
  setMatchingShipperId(state, payload) {
    state.matchingShipperId = payload
  },
  setMatchingConsigneeId(state, payload) {
    state.matchingConsigneeId = payload
  },
  setCarrierMatches(state, payload) {
    state.carrierMatches = payload
  },
  setCarrierMatchListCard(state, payload) {
    state.carrierMatchListCard = payload
  },
  setOutboundLoads(state, payload) {
    state.outboundLoads = payload
  },
  setMatchDialog(state, payload) {
    state.matchDialog = payload
  },
  setDialogLoad(state, payload) {
    state.dialogLoad = payload
  },
  setBackhualLoads(state, payload) {
    state.backhualLoads = payload
  },
  setMatchingLoads(state, payload) {
    state.matchingLoads = payload
  },
  setQuoteList(state, payload) {
    state.quoteList = payload
  },
  setQuoteListByLoad(state, payload) {
    state.quoteListByLoad = payload
  },
  setQuoteListByCarrier(state, payload) {
    state.quoteListByCarrier = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
