import api from "@/api/api"
import common from "@/utils/common"
import _camelCase from "lodash/camelCase"
import _mapKeys from "lodash/mapKeys"

const snackbarType = common.data.snackbarType

const state = {
  loadboards: [],
  performingLoadboardAction: {},
}

const getters = {
  loadboards: state => state.loadboards,
  getPerformingLoadboardAction: state => loadId => Boolean(state.performingLoadboardAction[loadId]),
}

const actions = {
  GET_LOADBOARDS: ({ commit }) => {
    const loadboards = common.methods.getWithExpiry("loadboards")
    if (loadboards) {
      commit("setLoadboards", loadboards)
    } else {
      api
        .get(`${process.env.VUE_APP_BASE_URL}/integrations/all-loadboards/`)
        .then((response) => {
          const camelCaseLoadboards = common.methods.toCamelCase(response.data)
          common.methods.setWithExpiry(
            "loadboards",
            camelCaseLoadboards,
            process.env.VUE_APP_LOCALSTORAGE_TIME
          )
          commit("setLoadboards", camelCaseLoadboards)
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve loadboards",
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
        })
    }
  },
  SUBMIT_LOADBOARD_ACTION: async ({ commit, getters, rootGetters }, { action, loadId, loadboards}) => {
    commit("setPerformingLoadboardAction", { loadId, value: true})
    return api.post(`${process.env.VUE_APP_BASE_URL}/integrations/post-to-loadboards/${loadId}/`, {
      service_names: loadboards,
      action: action,
    }).then((response) => {
      const snackbar = {
        status: true,
        text: "Loadboard action completed",
        type: snackbarType.success,
      }
      commit("main/SET_SNACKBAR", snackbar, { root: true })

      return common.methods.toCamelCase(response.data.statuses.filter(loadboard => loadboard.status !== "ABORTED"))
    }).catch((e) => {
      const snackbar = {
        status: true,
        text: e.response.data,
        type: snackbarType.error,
      }
      commit("main/SET_SNACKBAR", snackbar, { root: true })
    }).finally(() => {
      commit("setPerformingLoadboardAction", { loadId, value: false })
    })
  }
}

const mutations = {
  setLoadboards(state, payload) {
    state.loadboards = payload
  },
  setPerformingLoadboardAction(state, { loadId, value }) {
    state.performingLoadboardAction = {
      ...state.performingLoadboardAction,
      [loadId]: value
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
