import api from "@/api/api"
import moment from "moment"
import common from "@/utils/common"
import carrierPaymentMethods from "./carrierPaymentMethods"
import _mapKeys from "lodash/mapKeys"
import _camelCase from "lodash/camelCase"
import _pick from "lodash/pick"

const snackbarType = common.data.snackbarType
const state = {
  carrierId: null,
  currentCarrier: {},
  carrierCompanys: {
    count: 0,
    next: null,
    previous: null,
    results: [
      {
        id: 0,
        name: null,
        mcNumber: null,
        dotNumber: null,
        email: null,
      },
    ],
  },
  carrierStats: {
    totalShipment: 0,
    averageMargin: 0,
  },
  carrierDocuments: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  carrierShipments: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  carrierLanes: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  carrierNotes: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  carrierInsurances: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  carrierContacts: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  carrierNoteDetail: {
    id: 0,
    createdAt: null,
    updatedAt: null,
    createdByDisplay: null,
    note: null,
  },
  uploadProgress: 0,
  carrierDocument: {
    id: 0,
    createdAt: null,
    updatedAt: null,
    uploadedByDisplay: null,
    file: null,
    fileName: null,
    name: null,
    documentType: null,
    documentTypeDisplay: null,
  },
  insuranceType: {
    id: null,
    name: null,
  },
  selectedTab: "tab-1",
  carrierPayments: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
}

const getters = {
  carrierId: (state) => {
    return state.carrierId
  },
  currentCarrier: (state) => {
    return state.currentCarrier
  },
  carrierCompanys: (state) => {
    return state.carrierCompanys
  },
  carrierStats: (state) => {
    return state.carrierStats
  },
  carrierDocuments: (state) => {
    return state.carrierDocuments
  },
  carrierShipments: (state) => {
    return state.carrierShipments
  },
  carrierLanes: (state) => {
    return state.carrierLanes
  },
  carrierNotes: (state) => {
    return state.carrierNotes
  },
  carrierInsurances: (state) => {
    return state.carrierInsurances
  },
  carrierContacts: (state) => {
    return state.carrierContacts
  },
  carrierNoteDetail: (state) => {
    return state.carrierNoteDetail
  },
  uploadProgress: (state) => {
    return state.uploadProgress
  },
  carrierDocument: (state) => {
    return state.carrierDocument
  },
  insuranceType: (state) => {
    return state.insuranceType
  },
  selectedTab: (state) => {
    return state.selectedTab
  },
  carrierPayments: (state) => {
    return state.carrierPayments
  },
}

const actions = {
  GET_CARRIER_COMPANY: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/detail/${payload}/`
      )
      .then((response) => {
        const carrier = _mapKeys(response.data, (_, key) => _camelCase(key))

        const carrierInfo = {
          ...carrier,
          equipmentTypes: {
            ..._pick(carrier, [
              "wreckerService",
              "rgn",
              "van",
              "powerOnly",
              "stepDeck",
              "flatbed",
              "reefer",
              "hotshot",
              "specialized",
            ]),
          },
          insuranceActive: carrier.insuranceActive === true ? "Active" : "Inactive",
          approved: carrier.setupPending === true ? "No" : "Yes",
        }
        commit("setCurrentCarrier", carrierInfo)
        commit("loadDetail/setCarrierInfo", carrierInfo, {
          root: true,
        })
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve carrier.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CARRIER_COMPANY_LIST: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/list-create/`,
        {
          params: payload,
        }
      )
      .then((response) => {
        const carrierData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const carrierDataRecord = data[i]
          const carrier = {
            id: carrierDataRecord.id,
            name: carrierDataRecord.name,
            mcNumber: carrierDataRecord.mc_number,
            dotNumber: carrierDataRecord.dot_number,
            phone: carrierDataRecord.phone,
            email: carrierDataRecord.email,
            baseLocation: carrierDataRecord.base_location,
            shipmentsCount: carrierDataRecord.shipment_count,
            blacklist:
              carrierDataRecord.blacklist === true ? "Inactive" : "Active",
            insuranceActive:
              carrierDataRecord.insurance_active === true
                ? "Active"
                : "Inactive",
            approved: carrierDataRecord.setup_pending === true ? "No" : "Yes",
            carrierManager: carrierDataRecord.carrier_manager,
          }
          carrierData.results.push(carrier)
        }
        commit("setCarrierCompanys", carrierData)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve carrier.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CARRIER_STATS: ({ commit }, payload) => {
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/stats/${payload}/`
      )
      .then((response) => {
        const data = response.data
        if (data !== null) {
          const objStats = {
            totalShipment: data.total_shipments,
            averageMargin: data.average_margin,
          }
          commit("setCarrierStats", objStats)
        }
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Carrier Stats.",
          type: snackbarType.error,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CARRIER_DOCUMENTS: ({ commit }, carrierId) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/document/${carrierId}/?limit=10&offset=0`
      )
      .then((response) => {
        const documentData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const documentRecord = data[i]
          const document = {
            id: documentRecord.id,
            createdAt: moment(String(documentRecord.created_at)).isValid()
              ? moment(String(documentRecord.created_at)).format(
                  "MM-DD-YYYY hh:mm:ss"
                )
              : null,
            modifyDetail: {
              updatedAt: moment(String(documentRecord.updated_at)).isValid()
                ? moment(String(documentRecord.updated_at)).format(
                    "MM-DD-YYYY hh:mm:ss"
                  )
                : null,
              uploadedByDisplay: documentRecord.uploaded_by_display,
            },
            file: documentRecord.file,
            fileName: documentRecord.file_name,
            name: documentRecord.name,
            documentType: documentRecord.document_type,
            documentTypeDisplay: documentRecord.document_type_display,
          }
          documentData.results.push(document)
        }
        commit("setCarrierDocuments", documentData)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Carrier Documents.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CARRIER_SHIPMENTS: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/historical-loads/${payload.carrierId}/`,
        {
          params: payload.queryParams,
        }
      )
      .then((response) => {
        const shipmentData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const shipmentRecord = data[i]
          const shipment = {
            carrierSalesRep: shipmentRecord.carrier_sales_rep,
            id: shipmentRecord.id,
            customerCompany: shipmentRecord.customer_company,
            pickupDate: shipmentRecord.pickup_date,
            deliveryDate: shipmentRecord.delivery_date,
            customerReferenceId: shipmentRecord.customer_reference_id,
            poNumber: shipmentRecord.po_number,
            shipper: shipmentRecord.shipper,
            consignee: shipmentRecord.consignee,
            customerPrice: shipmentRecord.customer_price,
            carrierPrice: shipmentRecord.carrier_price,
            equipmentType: shipmentRecord.equipment_type,
            loadStatus: shipmentRecord.load_status,
            margin: shipmentRecord.percent_margin,
            archived: shipmentRecord.archived,
            archivedAt: shipmentRecord.archived_at,
          }
          shipmentData.results.push(shipment)
        }
        commit("setCarrierShipments", shipmentData)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Carrier Shipments.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CARRIER_LANES: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .get(payload)
        .then((response) => {
          const laneData = {
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            results: [],
          }
          const data = response.data.results
          for (let i = 0; i < data.length; i++) {
            const laneRecord = data[i]
            let srcLocation = null
            let dstLocation = null
            if (laneRecord.src_location) {
              srcLocation = {
                id: laneRecord.src_location.id,
                name: laneRecord.src_location.name,
                address: laneRecord.src_location.address,
                city: laneRecord.src_location.city,
                stateProvinceRegion:
                  laneRecord.src_location.state_province_region,
                postalCode: laneRecord.src_location.postal_code,
                country: laneRecord.src_location.country,
                latitude: laneRecord.src_location.latitude,
                longitude: laneRecord.src_location.longitude,
                currentPlace: laneRecord.src_location.current_place,
                displayPlace:
                  laneRecord.src_location.city +
                  ", " +
                  laneRecord.src_location.state_province_region,
              }
            }

            if (laneRecord.dst_location) {
              dstLocation = {
                id: laneRecord.dst_location.id,
                name: laneRecord.dst_location.name,
                address: laneRecord.dst_location.address,
                city: laneRecord.dst_location.city,
                stateProvinceRegion:
                  laneRecord.dst_location.state_province_region,
                postalCode: laneRecord.dst_location.postal_code,
                country: laneRecord.dst_location.country,
                latitude: laneRecord.dst_location.latitude,
                longitude: laneRecord.dst_location.longitude,
                currentPlace: laneRecord.dst_location.current_place,
                displayPlace:
                  laneRecord.dst_location.city +
                  ", " +
                  laneRecord.dst_location.state_province_region,
              }
            }
            const lane = {
              id: laneRecord.id,
              srcLocation: srcLocation,
              dstLocation: dstLocation,
              createdAt: laneRecord.created_at,
              updatedAt: laneRecord.updated_at,
              expiredAt: laneRecord.expired_at,
              archivedAt: laneRecord.archived_at,
              srcDate: laneRecord.src_date,
              dstDate: laneRecord.dst_date,
              quantityTrucks: laneRecord.quantity_trucks,
              quantityLoads: laneRecord.quantity_loads,
              carrierBid: laneRecord.carrier_bid,
              source: laneRecord.source,
              sourceDisplay: laneRecord.source_display,
              equipmentType: {
                name: laneRecord.equipment_type,
                id: laneRecord.equipment_type,
              },
              type: laneRecord.type,
              typeDisplay: laneRecord.type_display,
              miles: laneRecord.miles,
              originLocationList: [],
              destinationLocationList: [],
              originQuery: "",
              destinationQuery: "",
            }
            laneData.results.push(lane)
          }
          commit("setCarrierLanes", laneData)
          commit("generic/setLoadingIcon", false, { root: true })
          resolve()
        })
        .catch(() => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve Carrier Lanes.",
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject()
        })
    })
  },
  GET_CARRIER_NOTES: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(payload)
      .then((response) => {
        const noteData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const noteRecord = data[i]
          const note = {
            id: noteRecord.id,
            createdAt: moment(String(noteRecord.created_at)).isValid()
              ? moment(String(noteRecord.created_at)).format(
                  "MM-DD-YYYY hh:mm:ss"
                )
              : null,
            updatedAt: moment(String(noteRecord.updated_at)).isValid()
              ? moment(String(noteRecord.updated_at)).format(
                  "MM-DD-YYYY hh:mm:ss"
                )
              : null,
            createdByDisplay: noteRecord.created_by_display,
            note: noteRecord.note,
          }
          noteData.results.push(note)
        }
        commit("setCarrierNotes", noteData)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Carrier Notes.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CARRIER_INSURANCES: ({ commit }, payload) => {
    api
      .get(payload)
      .then((response) => {
        const insuranceData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const insuranceRecord = data[i]
          const insurance = {
            id: insuranceRecord.id,
            createdAt: insuranceRecord.created_at,
            updatedAt: insuranceRecord.updated_at,
            coverageAmount: insuranceRecord.coverage_amount,
            expirationDate: insuranceRecord.expiration_date,
            provider: insuranceRecord.provider,
            producer: insuranceRecord.producer,
            policyNumber: insuranceRecord.policy_number,
            notes: insuranceRecord.notes,
            insuranceType: insuranceRecord.insurance_type,
            insuranceTypeDisplay: insuranceRecord.insurance_type_display,
          }
          insuranceData.results.push(insurance)
        }
        commit("setCarrierInsurances", insuranceData)
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Carrier Insurance.",
          type: snackbarType.error,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CARRIER_INSURANCE_TYPES: ({ commit }) => {
    const insuranceTypes = common.methods.getWithExpiry("insurance_types")
    if (insuranceTypes) {
      commit("setInsuranceType", insuranceTypes)
    } else {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier-insurance-types/`
        )
        .then((response) => {
          const insuranceType = []
          const data = response.data
          for (let i = 0; i < data.length; i++) {
            const record = data[i]
            const insurance = {
              id: record.id,
              name: record.name,
            }
            insuranceType.push(insurance)
          }
          common.methods.setWithExpiry(
            "insurance_types",
            insuranceType,
            process.env.VUE_APP_LOCALSTORAGE_TIME
          )
          commit("setInsuranceType", insuranceType)
        })
        .catch(() => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve Carrier Insurance Type.",
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
        })
    }
  },
  GET_CARRIER_CONTACTS: ({ commit }, payload) => {
    api
      .get(payload)
      .then((response) => {
        const contactData = {
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: [],
        }
        const data = response.data.results
        for (let i = 0; i < data.length; i++) {
          const contactRecord = data[i]
          const contact = {
            id: contactRecord.id,
            createdAt: contactRecord.created_at,
            updatedAt: contactRecord.updated_at,
            name: contactRecord.name,
            phone: contactRecord.phone,
            email: contactRecord.email,
            isPrimary: contactRecord.is_primary,
            sendOffers: contactRecord.send_offers,
            isDispatch: contactRecord.is_dispatch,
            isAccounting: contactRecord.is_accounting,
            isClaims: contactRecord.is_claims,
            isDriver: contactRecord.is_driver,
          }
          contactData.results.push(contact)
        }
        commit("setCarrierContacts", contactData)
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Carrier Contact.",
          type: snackbarType.error,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  UPDATE_CARRIER_COMPANY: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .put(
          `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/detail/${payload.id}/`,
          payload.carrier
        )
        .then((response) => {
          const snackbar = {
            status: true,
            text: "Carrier updated successfully.",
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          dispatch("GET_CARRIER_COMPANY", payload.id)
          resolve(response.data)
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to update carrier.",
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  ADD_CARRIER_CONTACT: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .post(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/contact/${payload.carrierId}/`,
        payload.contact
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Carrier contact added successfully.",
          type: snackbarType.success,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: e.response?.data ?? "An unexpected error has occured.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  UPDATE_CARRIER_CONTACT: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .put(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/contact-rud/${payload.contactId}/`,
        payload.contact
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Carrier contact updated successfully.",
          type: snackbarType.success,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: e.response.data,
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  DELETE_CARRIER_CONTACT: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .delete(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/contact-rud/${payload.contactId}/`
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Contact deleted successfully.",
          type: snackbarType.success,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to delete contact.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  ADD_CARRIER_INSURANCE: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .post(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/insurance/${payload.carrierId}/`,
        payload.insurance
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Carrier insurance added successfully.",
          type: snackbarType.success,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to create insurance.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  UPDATE_CARRIER_INSURANCE: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .put(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/insurance-rud/${payload.insuranceId}/`,
        payload.insurance
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Carrier insurance updated successfully.",
          type: snackbarType.success,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to update insurance.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  DELETE_CARRIER_INSURANCE: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .delete(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/insurance-rud/${payload.insuranceId}/`
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Insurance deleted successfully.",
          type: snackbarType.success,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to delete insurance.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CARRIER_NOTE_DETAIL: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/note-rud/${payload}/`
      )
      .then((response) => {
        const note = {
          id: response.data.id,
          createdAt: response.data.created_at,
          updatedAt: response.data.updated_at,
          createdByDisplay: response.data.created_by_display,
          note: response.data.note,
        }
        commit("setCarrierNoteDetail", note)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Carrier Notes.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  ADD_CARRIER_NOTE: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .post(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/note/${payload.carrierId}/`,
        payload.data
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Carrier note added successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_CARRIER_NOTES", payload.noteURL)
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to add note.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  UPDATE_CARRIER_NOTE: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .put(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/note-rud/${payload.noteId}/`,
        payload.data
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Carrier note updated successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_CARRIER_NOTES", payload.noteURL)
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to update note.",
          type: snackbarType.error,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  DELETE_CARRIER_NOTE: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .delete(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/note-rud/${payload.noteId}/`
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Note deleted successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_CARRIER_NOTES", payload.noteURL)
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to delete note.",
          type: snackbarType.error,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CARRIER_DOCUMENT_DETAIL: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .get(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/document-rud/${payload}/`
      )
      .then((response) => {
        const document = {
          id: response.data.id,
          createdAt: response.data.created_at,
          updatedAt: response.data.updated_at,
          uploadedByDisplay: response.data.uploaded_by_display,
          file: response.data.file,
          fileName: response.data.file_name,
          name: response.data.name,
          documentType: response.data.document_type,
          documentTypeDisplay: response.data.document_type_display,
        }
        commit("setCarrierDocument", document)
        commit("generic/setLoadingIcon", false, { root: true })
      })
      .catch(() => {
        const snackbar = {
          status: true,
          text: "Failed to retrieve Carrier Documents.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  ADD_CARRIER_DOCUMENT: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/document/${payload.carrierId}/`,
          payload.fmData,
          {
            onUploadProgress: function (progressEvent) {
              commit(
                "setUploadProgress",
                parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                )
              )
            },
          }
        )
        .then((response) => {
          const snackbar = {
            status: true,
            text: "File added successfully.",
            type: snackbarType.success,
          }
          resolve(
            commit("main/SET_SNACKBAR", snackbar, { root: true }),
            dispatch("GET_CARRIER_DOCUMENTS", payload.carrierId),
            commit("setUploadProgress", 0)
          )
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to add file.",
            type: snackbarType.error,
          }
          reject(
            commit("setUploadProgress", 0),
            commit("main/SET_SNACKBAR", snackbar, { root: true })
          )
        })
    })
  },
  UPDATE_CARRIER_DOCUMENT: ({ commit, dispatch }, payload) => {
    api
      .put(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/document-rud/${payload.id}/`,
        payload.fmData,
        {
          onUploadProgress: function (progressEvent) {
            commit(
              "setUploadProgress",
              parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              )
            )
          },
        }
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "File updated successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_CARRIER_DOCUMENTS", payload.carrierId)
        commit("setUploadProgress", 0)
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to update file.",
          type: snackbarType.error,
        }
        commit("setUploadProgress", 0)
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  DELETE_CARRIER_DOCUMENT: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .delete(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/document-rud/${payload.id}/`
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Document deleted successfully.",
          type: snackbarType.success,
        }
        commit("main/SET_SNACKBAR", snackbar, { root: true })
        dispatch("GET_CARRIER_DOCUMENTS", payload.carrierId)
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to delete document.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  ADD_CARRIER_LANE: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/lane/${payload.carrier_company}/`,
          payload
        )
        .then((response) => {
          const snackbar = {
            status: true,
            text: "Carrier lane added successfully.",
            type: snackbarType.success,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          resolve(payload)
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to add carrier lane.",
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  UPDATE_CARRIER_LANE: ({ commit }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .put(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/lane-rud/${payload.id}/`,
        payload.data
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Carrier lane updated successfully.",
          type: snackbarType.success,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to update lane.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  DELETE_CARRIER_LANE: ({ commit, dispatch }, payload) => {
    commit("generic/setLoadingIcon", true, { root: true })
    api
      .delete(
        `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/lane-rud/${payload}/`
      )
      .then((response) => {
        const snackbar = {
          status: true,
          text: "Lane deleted successfully.",
          type: snackbarType.success,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
      .catch((e) => {
        const snackbar = {
          status: true,
          text: "Failed to delete lane.",
          type: snackbarType.error,
        }
        commit("generic/setLoadingIcon", false, { root: true })
        commit("main/SET_SNACKBAR", snackbar, { root: true })
      })
  },
  GET_CARRIER_PAYMENTS: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/billing/api/carrier-payment-list/${payload.id}/`,
          {
            params: payload.params,
          }
        )
        .then((response) => {
          const paymentData = {
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            results: [],
          }
          const data = response.data.results
          for (let i = 0; i < data.length; i++) {
            const paymentRecord = data[i]
            const payment = {
              id: paymentRecord.id,
              createdAt: paymentRecord.created_at,
              loadNumber: paymentRecord.load_id,
              loadStatus: paymentRecord.load_status,
              loadShipper: paymentRecord.load_shipper,
              loadConsignee: paymentRecord.load_consignee,
              loadEquipmentType: paymentRecord.load_equipment_type,
              loadPickupDate: paymentRecord.load_pickup_date,
              loadDeliveryDate: paymentRecord.load_delivery_date,
              carrierPay: paymentRecord.load_margin.carrier_total_price,
              customerPay: paymentRecord.load_margin.customer_total_price,
              margin: paymentRecord.load_margin.margin,
              carrierName: paymentRecord.carrier_name,
              carrierId: paymentRecord.carrier_id,
              customerName: paymentRecord.customer_name,
              checkId: paymentRecord.check_id,
              name: paymentRecord.name,
              description: paymentRecord.description,
              paymentMethod: paymentRecord.payment_method,
              paymentType: paymentRecord.payment_type,
              statusDisplay: paymentRecord.status_display,
              invoiceNumber: paymentRecord.invoice_number,
            }
            paymentData.results.push(payment)
          }
          commit("setCarrierPayments", paymentData)
          commit("generic/setLoadingIcon", false, { root: true })
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to retrieve carrier payments.",
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
  UPDATE_CUSTOMER_CARRIER: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .put(
          `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier-customer-relationship-rud/`,
          {
            carrier_company_id: payload.id,
            customer_company_id: payload.customerCompany,
            network: payload.network,
          }
        )
        .then((response) => {
          const snackbar = {
            status: true,
            text: response.data,
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          resolve(payload)
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: e.response.data,
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("generic/setLoadingIcon", false, { root: true })
          reject(e.response)
        })
    })
  },
  DELETE_CUSTOMER_FROM_CARRIER: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .delete(
          `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier-customer-relationship-rud/`,
          {
            data: {
              carrier_company_id: payload.id,
              customer_company_id: payload.customerCompany,
            },
          }
        )
        .then((response) => {
          const snackbar = {
            status: true,
            text: response.data,
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: e.response.data,
            type: snackbarType.error,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("generic/setLoadingIcon", false, { root: true })
          reject(e.response)
        })
    })
  },
  APPROVE_CARRIER: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("generic/setLoadingIcon", true, { root: true })
      api
        .post(
          `${process.env.VUE_APP_BASE_URL}/accounts/api/carrier/approve-carrier/${payload}/`
        )
        .then((response) => {
          const snackbar = {
            status: true,
            text: "Carrier approved successfully.",
            type: snackbarType.success,
          }
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          commit("generic/setLoadingIcon", false, { root: true })
          resolve()
        })
        .catch((e) => {
          const snackbar = {
            status: true,
            text: "Failed to approve carrier.",
            type: snackbarType.error,
          }
          commit("generic/setLoadingIcon", false, { root: true })
          commit("main/SET_SNACKBAR", snackbar, { root: true })
          reject(e)
        })
    })
  },
}

const mutations = {
  setCarrierId(state, payload) {
    state.carrierId = payload
  },
  setCurrentCarrier(state, payload) {
    state.currentCarrier = payload
  },
  setCarrierCompanys(state, payload) {
    state.carrierCompanys = payload
  },
  setCarrierStats(state, payload) {
    state.carrierStats = payload
  },
  setCarrierDocuments(state, payload) {
    state.carrierDocuments = payload
  },
  setCarrierShipments(state, payload) {
    state.carrierShipments = payload
  },
  setCarrierLanes(state, payload) {
    state.carrierLanes = payload
  },
  setCarrierNotes(state, payload) {
    state.carrierNotes = payload
  },
  setCarrierInsurances(state, payload) {
    state.carrierInsurances = payload
  },
  setCarrierContacts(state, payload) {
    state.carrierContacts = payload
  },
  setCarrierNoteDetail(state, payload) {
    state.carrierNoteDetail = payload
  },
  setCarrierDocument(state, payload) {
    state.carrierDocument = payload
  },
  setInsuranceType(state, payload) {
    state.insuranceType = payload
  },
  setUploadProgress(state, payload) {
    state.uploadProgress = payload
  },
  setCurrentTab(state, payload) {
    state.selectedTab = payload
  },
  setCarrierPayments(state, payload) {
    state.carrierPayments = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    paymentMethods: carrierPaymentMethods
  }
}
